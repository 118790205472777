import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './HierarchyComponent.css';
import Placeholder from '../utils/Placeholder';
import moment from 'moment';

const AlpSpreadsheet = () => {
    const [data, setData] = useState([]);
    const [dailyActivity, setDailyActivity] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [weeks, setWeeks] = useState([]);
    const [selectedWeekIndex, setSelectedWeekIndex] = useState(0);
    const [officialActivity, setOfficialActivity] = useState([]);
    const [weeklyData, setWeeklyData] = useState([]);
    const [reportType, setReportType] = useState('Weekly Recap');
    const tableContainerRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userId = localStorage.getItem('userId');
                const responseRGA = await axios.get('https://ariaslogin-4a95935f6093.herokuapp.com/api/rgaHierarchy', { params: { userId } });
                const reportTypes = ['Weekly Recap', 'MTD Recap', 'YTD Recap'];
                let allOfficialActivity = [];

                for (const reportType of reportTypes) {
                    const responseOfficialActivity = await axios.get('https://ariaslogin-4a95935f6093.herokuapp.com/api/getOfficialActivity', {
                        params: { REPORT: reportType }
                    });
                    if (responseOfficialActivity.data.success) {
                        const officialData = responseOfficialActivity.data.data.map(activity => ({
                            ...activity,
                            reportDate: new Date(activity.reportdate).toISOString().split('T')[0],
                            reportType
                        }));
                        allOfficialActivity = [...allOfficialActivity, ...officialData];
                    }
                }

                if (responseRGA.data.success) {
                    setData(responseRGA.data.data);
                    setOfficialActivity(allOfficialActivity);
                    const uniqueReportDates = getUniqueReportDates(allOfficialActivity);
                    setWeeks(uniqueReportDates);
                    setSelectedWeekIndex(0);
                } else {
                    setError('No data found');
                }
            } catch (error) {
                setError('Error fetching data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (officialActivity.length > 0 && weeks.length > 0) {
            filterOfficialActivity(selectedWeekIndex);
        }
    }, [selectedWeekIndex, reportType]);

    const filterOfficialActivity = (weekIndex) => {
        const selectedDate = weeks[weekIndex];
        const filteredActivity = officialActivity.filter(activity =>
            activity.reportDate === selectedDate && activity.REPORT === reportType
        );
        setDailyActivity(filteredActivity);
    };

    const getUniqueReportDates = (data) => {
        const reportDates = new Set();
        data.forEach(item => {
            const reportDate = new Date(item.reportDate).toISOString().split('T')[0];
            reportDates.add(reportDate);
        });
        return Array.from(reportDates).sort((a, b) => new Date(b) - new Date(a));
    };

    const calculateNetToGross = (net, gross) => {
        if (gross === 0 || gross === 'N/A') {
            return 'N/A';
        }
        return (net / gross).toFixed(2);
    };

    const buildHierarchy = (data) => {
        const hierarchy = [];
        const map = {};
        const inactiveNodes = [];

        data.forEach(item => {
            map[item.lagnname] = { ...item, children: [] };
        });

        data.forEach(item => {
            if (item.managerActive === 'n') {
                inactiveNodes.push(map[item.lagnname]);
                return;
            }

            if (item.sa && map[item.sa]) {
                map[item.sa].children.push(map[item.lagnname]);
            } else if (item.ga && map[item.ga]) {
                map[item.ga].children.push(map[item.lagnname]);
            } else if (item.mga && map[item.mga]) {
                map[item.mga].children.push(map[item.lagnname]);
            } else if (item.rga && map[item.rga]) {
                map[item.rga].children.push(map[item.lagnname]);
            } else {
                hierarchy.push(map[item.lagnname]);
            }
        });

        return { sortedHierarchy: hierarchy };
    };

    const renderAgents = (agent) => {
        const totalALP = 'someValueForTotalALP'; // Implement this if necessary
        const lvl1Net = 'someValueForLvl1Net'; // Implement this if necessary
        const lvl1Gross = 'someValueForLvl1Gross'; // Implement this if necessary
        const netToGross = calculateNetToGross(lvl1Net, lvl1Gross);

        return (
            <React.Fragment key={agent.lagnname}>
                <tr>
                    <td>{agent.additionalDetails}</td>
                    <td>{agent.lagnname}</td>
                    <td>{moment(agent.esid).format('MM/DD/YY')}</td>
                    {reportType === 'Weekly Recap' && <td>{totalALP}</td>}
                    <td>{lvl1Gross}</td>
                    <td>{lvl1Net}</td>
                    <td>{netToGross}</td>
                </tr>
                {agent.children && agent.children.map(subordinate => renderAgents(subordinate))}
            </React.Fragment>
        );
    };

    return (
        <div>
            {loading ? (
                <Placeholder />
            ) : (
                <>
                    <div className="tabs">
                        <button onClick={() => setReportType('Weekly Recap')}>Weekly Recap</button>
                        <button onClick={() => setReportType('MTD Recap')}>MTD Recap</button>
                        <button onClick={() => setReportType('YTD Recap')}>YTD Recap</button>
                    </div>
                    <div>
                        <select onChange={(e) => setSelectedWeekIndex(weeks.indexOf(e.target.value))}>
                            {weeks.map(week => (
                                <option value={week} key={week}>{week}</option>
                            ))}
                        </select>
                    </div>
                    <table ref={tableContainerRef}>
                        <thead>
                            <tr>
                                <th>MGA</th>
                                <th>Name</th>
                                <th>Hire Date</th>
                                {reportType === 'Weekly Recap' && <th>Reported ALP</th>}
                                <th>LVL 1 Gross</th>
                                <th>LVL 1 Net</th>
                                <th>Net to Gross</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map(mga => {
                                const { sortedHierarchy } = buildHierarchy([mga]);
                                return sortedHierarchy.map(agent => renderAgents(agent));
                            })}
                        </tbody>
                    </table>
                </>
            )}
        </div>
    );
};

export default AlpSpreadsheet;
