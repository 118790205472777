import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import './AddResource.css';  // Assuming these styles contain modal and form styling
import './GridContent.css';  // Assuming these styles contain grid-cat and related styles

const AdminManageResources = () => {
  const [resources, setResources] = useState([]);
  const [editResourceId, setEditResourceId] = useState(null);
  const [sectionname, setSectionName] = useState('');
  const [customSection, setCustomSection] = useState(''); // For the custom section
  const [name, setName] = useState('');
  const [file, setFile] = useState(null); // For file upload
  const [url, setUrl] = useState(''); // For direct URL input
  const [resourceType, setResourceType] = useState('file'); // Determines if it's file or URL
  const [viewType, setViewType] = useState('table'); // Toggle between table and grid view
  const [showModal, setShowModal] = useState(false); // Modal for add/update
  const [showConfirmDelete, setShowConfirmDelete] = useState(false); // Modal for delete confirmation
  const [deleteResourceId, setDeleteResourceId] = useState(null); // Resource to be deleted

  // Default section name options
  const sectionOptions = [
    { value: 'training', label: 'Training' },
    { value: 'learning', label: 'Learning' },
    { value: 'meeting links', label: 'Meeting Links' },
    { value: 'rebuttals', label: 'Rebuttals' },
    { value: 'growth', label: 'Growth' },
    { value: 'managers', label: 'Managers' },
    { value: 'misc', label: 'Misc' },
    { value: 'mods & bulletins', label: 'Mods & Bulletins' },
    { value: 'paperwork', label: 'Paperwork' },
    { value: 'retention', label: 'Retention' },
    { value: 'underwriting', label: 'Underwriting' },
    { value: 'custom', label: 'Custom Category' } // Option for custom category
  ];

  useEffect(() => {
    // Fetch resources from the backend
    fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/resources/admin/all')
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setResources(data.resources);
        } else {
          console.error(data.message);
        }
      })
      .catch((err) => {
        console.error('Error fetching resources:', err);
      });
  }, []);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('sectionname', sectionname === 'custom' ? customSection : sectionname);
    formData.append('name', name);

    if (resourceType === 'file' && file) {
      formData.append('file', file);
    } else if (resourceType === 'url') {
      formData.append('url', url);
    }

    const apiUrl = editResourceId 
      ? `https://ariaslogin-4a95935f6093.herokuapp.com/api/resources/admin/update/${editResourceId}`
      : 'https://ariaslogin-4a95935f6093.herokuapp.com/api/resources/admin/add';

    fetch(apiUrl, {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          alert('Resource saved successfully');
          // Clear inputs after submission
          setSectionName('');
          setCustomSection('');
          setName('');
          setFile(null);
          setUrl('');
          setResourceType('file');
          setEditResourceId(null);
          setShowModal(false); // Close the modal

          // Refresh the resources list
          fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/resources/admin/all')
            .then((res) => res.json())
            .then((data) => setResources(data.resources));
        } else {
          alert(data.message || 'Error saving resource');
        }
      })
      .catch((err) => console.error('Error saving resource:', err));
  };

  const handleEdit = (resource) => {
    setEditResourceId(resource.id);
    setSectionName(resource.sectionname);
    setName(resource.name);
    setUrl(resource.url);
    setResourceType(resource.url ? 'url' : 'file');
    setShowModal(true); // Open the modal for editing
  };

  const handleDelete = () => {
    fetch(`https://ariaslogin-4a95935f6093.herokuapp.com/api/resources/admin/delete/${deleteResourceId}`, {
      method: 'DELETE',
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          // Remove the resource from the state
          setResources(resources.filter((resource) => resource.id !== deleteResourceId));
          alert('Resource deleted successfully');
          setShowConfirmDelete(false); // Close the confirmation modal
        } else {
          alert('Error deleting resource');
        }
      })
      .catch((err) => console.error('Error deleting resource:', err));
  };

  const handleDeleteConfirm = (resourceId) => {
    setDeleteResourceId(resourceId);
    setShowConfirmDelete(true); // Show the confirmation modal
  };

  // Group resources by sectionname
  const groupedResources = resources.reduce((acc, resource) => {
    const section = resource.sectionname || 'Misc'; // Default to Misc if no sectionname
    if (!acc[section]) {
      acc[section] = [];
    }
    acc[section].push(resource);
    return acc;
  }, {});

  return (
    <div className="admin-manage-resources">
      <h2>Manage Resources</h2>

      {/* Tab system */}
      <div className="tabs">
        <input
          type="radio"
          id="table"
          name="view_type"
          value="table"
          checked={viewType === 'table'}
          onChange={() => setViewType('table')}
        />
        <label htmlFor="table">Table View</label>

        <input
          type="radio"
          id="grid"
          name="view_type"
          value="grid"
          checked={viewType === 'grid'}
          onChange={() => setViewType('grid')}
        />
        <label htmlFor="grid">Grid View</label>

        <button onClick={() => setShowModal(true)} className="add-resource-btn">+ Add Resource</button>
      </div>

      {viewType === 'table' ? (
        <div className="resources-list">
          <table className="hierarchyTable">
            <thead>
              <tr>
                <th>Section</th>
                <th>Name</th>
                <th>Resource Type</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {resources.length === 0 ? (
                <tr><td colSpan="4">No resources available.</td></tr>
              ) : (
                resources.map((resource) => (
                  <tr key={resource.id}>
                    <td>{resource.sectionname}</td>
                    <td>
                      <a href={resource.url} target="_blank" rel="noopener noreferrer">
                        {resource.name}
                      </a>
                    </td>
                    <td>{resource.url ? 'URL' : 'File'}</td>
                    <td>
                      <button onClick={() => handleEdit(resource)}>Edit</button>
                      <button onClick={() => handleDeleteConfirm(resource.id)}>Delete</button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="grid-content" id="resources">
          {Object.keys(groupedResources).length === 0 ? (
            <p>No resources available.</p>
          ) : (
            Object.keys(groupedResources).map((section) => (
              <div className="grid-cat" key={section}>
                <div className="category">{section}</div>
                {groupedResources[section].map((resource, index) => (
                  <div className="category-link" key={index}>
                    <a href={resource.url} target="_blank" rel="noopener noreferrer">
                      {resource.name}
                    </a>
                  </div>
                ))}
              </div>
            ))
          )}
        </div>
      )}

      {/* Modal for adding/updating resources */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h3>{editResourceId ? 'Edit Resource' : 'Add Resource'}</h3>
            <form onSubmit={handleSubmit}>
              {/* Section Name Dropdown */}
              <Select
                options={sectionOptions}
                value={sectionOptions.find(option => option.value === sectionname)}
                onChange={selectedOption => setSectionName(selectedOption.value)}
                placeholder="Select a Section"
                isSearchable
                className="custom-modal-select"
              />

              {/* Show custom section name input only if 'Custom Category' is selected */}
              {sectionname === 'custom' && (
                <input
                  type="text"
                  value={customSection}
                  placeholder="Enter Custom Section Name"
                  onChange={(e) => setCustomSection(e.target.value)}
                  required
                  className="custom-modal-input"
                />
              )}

              <input
                type="text"
                value={name}
                placeholder="Resource Name"
                onChange={(e) => setName(e.target.value)}
                required
                className="custom-modal-input"
              />

              {/* Resource type selection */}
              <div className="resource-type">
                <label>
                  <input
                    type="radio"
                    value="file"
                    checked={resourceType === 'file'}
                    onChange={() => setResourceType('file')}
                  />
                  Upload File
                </label>
                <label>
                  <input
                    type="radio"
                    value="url"
                    checked={resourceType === 'url'}
                    onChange={() => setResourceType('url')}
                  />
                  Enter URL
                </label>
              </div>

              {/* Conditionally render file input or URL input based on the selection */}
              {resourceType === 'file' ? (
                <input type="file" onChange={handleFileChange} required={editResourceId === null} />
              ) : (
                <input
                  type="text"
                  value={url}
                  placeholder="Enter URL"
                  onChange={(e) => setUrl(e.target.value)}
                  required
                />
              )}

              <button type="submit">{editResourceId ? 'Update Resource' : 'Add Resource'}</button>
              <button onClick={() => setShowModal(false)}>Cancel</button>
            </form>
          </div>
        </div>
      )}

      {/* Confirmation Modal for Delete */}
      {showConfirmDelete && (
        <div className="modal">
          <div className="modal-content">
            <h3>Are you sure you want to delete this resource?</h3>
            <button onClick={handleDelete}>Yes, Delete</button>
            <button onClick={() => setShowConfirmDelete(false)}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminManageResources;
