import React from 'react';
import AdminAlpSpreadsheet from './AdminAlpSpreadsheet';

const AdminDashboard = () => {
    // Your code here

    return (
        <div>
            <h1>Admin Dashboard</h1>
            <AdminAlpSpreadsheet />
            </div>
    );
};

export default AdminDashboard;