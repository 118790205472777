import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import './AddResource.css'; // Assuming styles are in AddResource.css

const EditResource = ({ showModal, setShowModal, resourceToEdit, setResourceToEdit, refreshResources }) => {
  const [sectionname, setSectionName] = useState('');
  const [customSection, setCustomSection] = useState(''); // For the custom section
  const [name, setName] = useState('');
  const [file, setFile] = useState(null);
  const [uploadType, setUploadType] = useState('file'); // 'file' or 'url'
  const [url, setUrl] = useState(''); // For the URL input
  const [MGAList, setMGAList] = useState([]);
  const [selectedMGAs, setSelectedMGAs] = useState([]);

  // Default section name options
  const sectionOptions = [
    { value: 'training', label: 'Training' },
    { value: 'learning', label: 'Learning' },
    { value: 'meeting links', label: 'Meeting Links' },
    { value: 'rebuttals', label: 'Rebuttals' },
    { value: 'growth', label: 'Growth' },
    { value: 'managers', label: 'Managers' },
    { value: 'misc', label: 'Misc' },
    { value: 'mods & bulletins', label: 'Mods & Bulletins' },
    { value: 'paperwork', label: 'Paperwork' },
    { value: 'retention', label: 'Retention' },
    { value: 'underwriting', label: 'Underwriting' },
    { value: 'custom', label: 'Custom Category' }, // Option for custom category
  ];

  // Get userRole and userId from localStorage
  const userRole = localStorage.getItem('userRole');
  const userId = localStorage.getItem('userId');

  // Fetch hierarchy information for RGA role
  useEffect(() => {
    if (userRole === 'RGA') {
      fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/getHierarchyInfo', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userId })
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            const uniqueMGAs = [...new Set(data.data
              .map(item => item.mga)
              .filter(mga => mga && mga.trim() !== ''))]; // Filter out empty or blank MGAs
            const formattedMGAs = uniqueMGAs.map(mga => ({ value: mga, label: mga }));
            setMGAList(formattedMGAs);
          } else {
            console.error('Error fetching hierarchy data:', data.message);
          }
        })
        .catch(err => console.error('Error:', err));
    } else if (userRole === 'MGA') {
      const mgaName = localStorage.getItem('agnName');
      setSelectedMGAs([{ value: mgaName, label: mgaName }]);
      setMGAList([{ value: mgaName, label: mgaName }]);
    }
  }, [userId, userRole]);

  // Pre-fill the form when editing a resource
  useEffect(() => {
    if (resourceToEdit) {
      setSectionName(resourceToEdit.sectionname);
      if (resourceToEdit.sectionname === 'custom') {
        setCustomSection(resourceToEdit.sectionname);
      }
      setName(resourceToEdit.name);
      setUrl(resourceToEdit.url);
      setUploadType(resourceToEdit.url ? 'url' : 'file');

      // Pre-fill selected MGAs
      if (resourceToEdit.MGA) {
        setSelectedMGAs([{ value: resourceToEdit.MGA, label: resourceToEdit.MGA }]);
      } else if (resourceToEdit.MGAs && Array.isArray(resourceToEdit.MGAs)) {
        setSelectedMGAs(resourceToEdit.MGAs.map(mga => ({ value: mga, label: mga })));
      } else {
        setSelectedMGAs([]);
      }
    }
  }, [resourceToEdit]);

  const resetForm = () => {
    setSectionName('');
    setCustomSection('');
    setName('');
    setFile(null);
    setUrl('');
    setUploadType('file');
    setSelectedMGAs([]);
    setResourceToEdit(null);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!resourceToEdit) {
      console.error('No resource selected for editing');
      return;
    }

    const formData = new FormData();

    // Append MGAs individually
    selectedMGAs.forEach(mga => {
      formData.append('MGAs[]', mga.value);
    });

    formData.append('sectionname', sectionname === 'custom' ? customSection : sectionname);
    formData.append('name', name);

    if (uploadType === 'file' && file) {
      formData.append('file', file); // Handle file upload
    } else if (uploadType === 'url' && url) {
      formData.append('url', url); // Handle URL submission
    }

    fetch(`https://ariaslogin-4a95935f6093.herokuapp.com/api/resources/admin/update/${resourceToEdit.id}`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Response from server:', data);
        setShowModal(false);
        resetForm();
        if (refreshResources) {
          refreshResources(); // Refresh the resources list after updating
        }
      })
      .catch((err) => console.error('Error updating resource:', err));
  };

  return (
    <>
      {showModal && (
        <div className="custom-modal">
          <div className="custom-modal-content">
            <div className="custom-modal-header">
              <h2 className="custom-modal-title">Update Resource</h2>
              <button
                className="custom-modal-close"
                onClick={() => {
                  setShowModal(false);
                  resetForm();
                }}
              >
                ×
              </button>
            </div>

            <form onSubmit={handleSubmit} className="custom-modal-form">
              {/* Section Name Dropdown */}
              <Select
                options={sectionOptions}
                value={sectionOptions.find((option) => option.value === sectionname)}
                onChange={(selectedOption) => setSectionName(selectedOption.value)}
                placeholder="Select a Section"
                isSearchable
                className="custom-modal-select"
              />

              {/* Show custom section name input only if 'Custom Category' is selected */}
              {sectionname === 'custom' && (
                <input
                  type="text"
                  value={customSection}
                  placeholder="Enter Custom Section Name"
                  onChange={(e) => setCustomSection(e.target.value)}
                  required
                  className="custom-modal-input"
                />
              )}

              <input
                type="text"
                value={name}
                placeholder="Resource Name"
                onChange={(e) => setName(e.target.value)}
                required
                className="custom-modal-input"
              />

              {/* Toggle between File and URL */}
              <div className="upload-type-toggle">
                <label>
                  <input
                    type="radio"
                    value="file"
                    checked={uploadType === 'file'}
                    onChange={() => setUploadType('file')}
                  />
                  Upload File
                </label>
                <label>
                  <input
                    type="radio"
                    value="url"
                    checked={uploadType === 'url'}
                    onChange={() => setUploadType('url')}
                  />
                  Enter URL
                </label>
              </div>

              {uploadType === 'file' ? (
                <input type="file" onChange={handleFileChange} className="custom-modal-file-input" />
              ) : (
                <input
                  type="url"
                  value={url}
                  placeholder="Enter Resource URL"
                  onChange={(e) => setUrl(e.target.value)}
                  required
                  className="custom-modal-input"
                />
              )}

              {/* MGA Select Dropdown */}
              {userRole === 'RGA' && (
                <Select
                  isMulti
                  options={MGAList}
                  value={selectedMGAs}
                  onChange={setSelectedMGAs}
                  placeholder="Select MGAs"
                  isSearchable
                  className="custom-modal-select"
                />
              )}

              {/* For MGA users, display their MGA name */}
              {userRole === 'MGA' && (
                <div className="mga-display">
                  <label>MGA:</label>
                  <span>{selectedMGAs.length > 0 ? selectedMGAs[0].label : ''}</span>
                </div>
              )}

              <button type="submit" className="custom-modal-submit">
                Update Resource
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default EditResource;
