import React from 'react';
import Slider from 'react-slick';
import './Tutorials.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AddResource from './AddResource';
import Resources from './Resources';
import AdminManageResources from './AdminManageResources';

const Tutorials = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2, // Show multiple slides at once for continuous effect
        slidesToScroll: 1,
        arrows: true,
        centerMode: true,
        centerPadding: '0'
    };

    return (
        <div className="container">
            
            <section className="tutorial-section">
                <h2>Agent Portal</h2>
                <Slider {...settings}>
                    <div className="video-container">
                        <iframe
                            src="https://player.vimeo.com/video/981507363"
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                            title="Vimeo Video 1"
                        ></iframe>
                    </div>

                    <div className="video-container">
                        <iframe
                            src="https://player.vimeo.com/video/981498870/"
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                            title="Vimeo Video 4"
                        ></iframe>
                    </div>
                </Slider>
            </section>



            {/* Add more sections as needed */}
        </div>
    );
};

export default Tutorials;
