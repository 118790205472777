import React, { useEffect, useState } from 'react';
import './GridContent.css';
import AddResource from './AddResource';
import EditResource from './EditResource';

const Content = () => {
  const [resources, setResources] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const mga = localStorage.getItem('agnName');
  const userRole = localStorage.getItem('userRole');

  const [showAddModal, setShowAddModal] = useState(false); // Control AddResource modal display
  const [showEditModal, setShowEditModal] = useState(false); // Control EditResource modal display
  const [resourceToEdit, setResourceToEdit] = useState(null); // Resource to be edited

  useEffect(() => {
    fetchResources();
  }, [mga]);

  const fetchResources = () => {
    if (mga) {
      fetch(`https://ariaslogin-4a95935f6093.herokuapp.com/api/resources/${mga}`)
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setResources(data.resources);
          } else {
            console.error(data.message);
          }
        })
        .catch((err) => {
          console.error('Error fetching resources:', err);
        });
    }
  };

  // Filter resources based on the search query
  const filteredResources = Object.keys(resources).reduce((acc, section) => {
    if (section.toUpperCase().includes(searchQuery.toUpperCase())) {
      acc[section] = resources[section];
    } else {
      const filteredLinks = resources[section].filter((resource) =>
        resource.name.toUpperCase().includes(searchQuery.toUpperCase())
      );
      if (filteredLinks.length > 0) {
        acc[section] = filteredLinks;
      }
    }
    return acc;
  }, {});

  const handleDelete = (resourceId) => {
    fetch(`https://ariaslogin-4a95935f6093.herokuapp.com/api/resources/admin/delete/${resourceId}`, {
      method: 'DELETE',
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          const updatedResources = Object.keys(resources).reduce((acc, section) => {
            acc[section] = resources[section].filter((resource) => resource.id !== resourceId);
            return acc;
          }, {});
          setResources(updatedResources);
        } else {
          console.error('Error deleting resource:', data.message);
        }
      })
      .catch((err) => console.error('Error deleting resource:', err));
  };

  const openAddModal = () => {
    setShowAddModal(true);
  };

  const openEditModal = (resource) => {
    setResourceToEdit(resource); // Pass the resource to be edited
    setShowEditModal(true); // Open the modal
  };

  return (
    <div>
      <div className="search-bar">
        <input
          type="text"
          id="searchInput"
          placeholder="Search categories or resources..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />

        {/* Show Add Resource button if userRole is MGA or RGA */}
        {userRole === 'MGA' || userRole === 'RGA' ? (
          <button className="add-resource-btn" onClick={openAddModal}>
            Add Resource
          </button>
        ) : null}

        {/* Modal for adding resources */}
        <AddResource
          showModal={showAddModal}
          setShowModal={setShowAddModal}
          refreshResources={fetchResources}
        />

        {/* Modal for editing resources */}
        <EditResource
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
          resourceToEdit={resourceToEdit}
          setResourceToEdit={setResourceToEdit}
          refreshResources={fetchResources}
        />
      </div>

      <div className="grid-content" id="resources">
        {Object.keys(filteredResources).length === 0 ? (
          <p>No resources found.</p>
        ) : (
          Object.keys(filteredResources).map((section) => (
            <div className="grid-cat" key={section}>
              <div className="category">
                <h4>
                  {section
                    .split(' ')
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                    .join(' ')}
                </h4>
              </div>
              <div>
                {filteredResources[section].map((resource) => (
                  <div className="category-link" key={resource.id}>
                    <a href={resource.url} target="_blank" rel="noopener noreferrer">
                      {resource.name}
                    </a>
                    {userRole === 'MGA' || userRole === 'RGA' ? (
                      <div className="resource-actions">
                        <button onClick={() => openEditModal(resource)} className="edit-btn">
                          Update
                        </button>
                        {!resource.is_default && (
                          <button onClick={() => handleDelete(resource.id)} className="delete-btn">
                            Delete
                          </button>
                        )}
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Content;
