import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ApplicationTable from './Verify/ApplicationTable';
import ApplicationDetails from './Verify/ApplicationDetails';
import TextMessageViewer from './Verify/TextMessageViewer';
import Placeholder from '../utils/Placeholder';

function AdminVerify() {
    const [verifyData, setVerifyData] = useState([]);
    const [verifyClientData, setVerifyClientData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isArchiveView, setIsArchiveView] = useState(false);
    const [selectedTabIndex, setSelectedTabIndex] = useState(() => {
        // Retrieve saved tab index from localStorage or default to 0
        return parseInt(localStorage.getItem('selectedTabIndex')) || 0;
    });
    
    // Track selected rows separately for each tab
    const [selectedUnverifiedRow, setSelectedUnverifiedRow] = useState(null);
    const [selectedVerifiedRow, setSelectedVerifiedRow] = useState(null);
    const [selectedDiscrepancyRow, setSelectedDiscrepancyRow] = useState(null);
    const [selectedQueuedRow, setSelectedQueuedRow] = useState(null);

    const capitalizeWords = (str) => {
        return str
            .replace(/_/g, ' ') // Replace underscores with spaces
            .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize each word
    };

    const questionTooltips = {
        senior_rejected: 'Was the senior rejected by any other insurance?',
        heart_lung: 'Has the insured experienced any heart or lung issues?',
        cirrhosis: 'Has the insured been diagnosed with cirrhosis?',
        amputation: 'Has the insured had any amputations?',
        cancer_senior: 'Has the insured ever been diagnosed with cancer?',
        oxygen: 'Is the insured currently on oxygen therapy?',
        anxiety_depression: 'Has the insured been diagnosed with anxiety or depression?',
        cancer: 'Has the insured ever been diagnosed with cancer?',
        chronic_illness: 'Does the insured suffer from any chronic illness?',
        diabetes: 'Does the insured have diabetes?',
        dui: 'Has the insured ever been convicted of a DUI?',
        er_visit: 'Has the insured visited the emergency room in the past year?',
        heart_issues: 'Does the insured have any heart-related issues?',
        high_blood_pressure: 'Does the insured have high blood pressure?',
        medications: 'Is the insured currently taking any prescribed medications?',
    };

    const handleRefresh = () => {
        setLoading(true);
        setError(null);
        fetchVerifyData();
    };

    const handleTabSelect = (index) => {
        setSelectedTabIndex(index);
        localStorage.setItem('selectedTabIndex', index); // Persist tab index
    };
    
        const fetchVerifyData = async () => {
            try {
                const verifyResponse = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/verify/all');
                const verifyResult = await verifyResponse.json();

                const verifyClientResponse = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/verifyclient/all');
                const verifyClientResult = await verifyClientResponse.json();
                if (verifyResult.success && verifyClientResult.success) {
                    setVerifyData(verifyResult.data);
                    setVerifyClientData(verifyClientResult.data);
                } else {
                    setError('Failed to fetch data from one or both endpoints.');
                }
            } catch (err) {
                setError('Error fetching data: ' + err.message);
            } finally {
                setLoading(false);
            }
        };

        useEffect(() => {
            fetchVerifyData();
        }, []);
        

    const parseInsuredInfo = (info) => {
        const [name, premium, trial, senior] = info.split(',');
        if (name === 'n/a') return null;

        return {
            name,
            premium,
            trial: trial === 'y' ? 'Trial' : 'Standard',
            type: senior === 'y' ? 'Senior' : 'Super Combo',
        };
    };

    const getSaleType = (row) => {
        const insureds = [];
        if (parseInsuredInfo(row.primary_info)) insureds.push(parseInsuredInfo(row.primary_info).type);
        if (parseInsuredInfo(row.spouse_info)) insureds.push(parseInsuredInfo(row.spouse_info).type);

        for (let i = 1; i <= 9; i++) {
            const childInfo = parseInsuredInfo(row[`child${i}_info`]);
            if (childInfo) insureds.push('Super Combo');
        }

        const allSenior = insureds.every((type) => type === 'Senior');
        const noSeniors = insureds.every((type) => type === 'Super Combo');

        return allSenior ? 'Senior' : noSeniors ? 'Super Combo' : 'Super Combo/Senior';
    };

    // Function to get the matching client verify data for a given application_id
    const getClientVerifyData = (application_id) => {
        return verifyClientData.find(clientRow => clientRow.application_id === application_id);
    };

    const getQueuedData = () => {
        return verifyData.filter(
            (verifyRow) =>
                !verifyClientData.some((clientRow) => clientRow.application_id === verifyRow.application_id) &&
                verifyRow.status === 'Queued' &&
                verifyRow.archive === (isArchiveView ? 'y' : 'n') // Check for archive status based on isArchiveView
        );
    };
    
    const getUnverifiedData = () => {
        return verifyData.filter(
            (verifyRow) =>
                !verifyClientData.some((clientRow) => clientRow.application_id === verifyRow.application_id) &&
                verifyRow.status !== 'Queued' &&
                verifyRow.archive === (isArchiveView ? 'y' : 'n') // Check for archive status based on isArchiveView
        );
    };
    
    const getVerifiedData = () => {
        return verifyData.filter((verifyRow) => {
            const matchingClientRow = verifyClientData.find(
                (clientRow) => clientRow.application_id === verifyRow.application_id
            );
    
            if (matchingClientRow) {
                // Define medical questions keys
                const medicalKeys = [
                    'amputation', 'anxiety_depression', 'cancer', 'cancer_senior', 'chronic_illness', 
                    'cirrhosis', 'diabetes', 'dui', 'er_visit', 'heart_issues', 'heart_lung', 
                    'high_blood_pressure', 'medications', 'oxygen', 'senior_rejected'
                ];
    
                // Allow rows where answers match exactly, whether they are both "n" or both "yes(insureds)"
                const allMedicalMatch = !medicalKeys.some((key) => {
                    const agentAnswer = verifyRow[`${key}_answer`];
                    const clientAnswer = matchingClientRow[key];
    
                    // Include rows with identical answers (either "n" or matching "yes(insureds)")
                    return agentAnswer !== clientAnswer;
                });
    
                const noVerificationIssues = (
                    matchingClientRow.account_verification === 'y' &&
                    matchingClientRow.application_verification === 'y' &&
                    matchingClientRow.agent_contact_request === 'No'
                );
    
                const ipAddressMismatch = verifyRow.agent_ip === matchingClientRow.client_ip;
    
                return allMedicalMatch && noVerificationIssues && !ipAddressMismatch && 
                       verifyRow.archive === (isArchiveView ? 'y' : 'n');
            }
    
            return false;
        });
    };
    
    
    
    const getDiscrepancyData = () => {
        return verifyData
            .filter((verifyRow) => {
                const isArchived = verifyRow.archive === 'y';
                const includeInTab = isArchiveView ? isArchived : !isArchived;
                return includeInTab;
            })
            .map((verifyRow) => {
                const matchingClientRow = verifyClientData.find(
                    (clientRow) => clientRow.application_id === verifyRow.application_id
                );
                const discrepancies = [];
    
                if (matchingClientRow) {
                    const medicalKeys = [
                        'amputation', 'anxiety_depression', 'cancer', 'cancer_senior', 'chronic_illness', 
                        'cirrhosis', 'diabetes', 'dui', 'er_visit', 'heart_issues', 'heart_lung', 
                        'high_blood_pressure', 'medications', 'oxygen', 'senior_rejected'
                    ];
    
                    medicalKeys.forEach((key) => {
                        const agentAnswer = verifyRow[`${key}_answer`] || 'n';
                        const clientAnswer = matchingClientRow[key] || 'n';
    
                        // Extract insured names from 'yes(insureds)' format for both agent and client answers
                        const agentInsureds = agentAnswer.toLowerCase().includes('yes(')
                            ? agentAnswer.match(/\(([^)]+)\)/)[1].split(',').map(name => name.trim().toLowerCase())
                            : [];
    
                        const clientInsureds = clientAnswer.toLowerCase().includes('yes(')
                            ? clientAnswer.match(/\(([^)]+)\)/)[1].split(',').map(name => name.trim().toLowerCase())
                            : [];
    
                        // Determine if there is a mismatch for specific insureds
                        const insuredMismatch = agentInsureds.some(insured => !clientInsureds.includes(insured)) ||
                                                clientInsureds.some(insured => !agentInsureds.includes(insured));
    
                        if ((agentAnswer !== clientAnswer && !agentInsureds.length && !clientInsureds.length) || insuredMismatch) {
                            discrepancies.push({ question: key, agentAnswer, clientAnswer });
                        }
                    });
    
                    // Other fields check
                    if (matchingClientRow.account_verification === 'n') {
                        discrepancies.push({ question: 'account_verification', issue: 'Account verification failed' });
                    }
                    if (matchingClientRow.application_verification === 'n') {
                        discrepancies.push({ question: 'application_verification', issue: 'Application verification failed' });
                    }
                    if (matchingClientRow.agent_contact_request.toLowerCase() !== 'no') {
                        discrepancies.push({ question: 'agent_contact_request', issue: 'Agent contact request present' });
                    }
                    if (verifyRow.agent_ip === matchingClientRow.client_ip) {
                        discrepancies.push({ question: 'IP Match', issue: 'IP addresses match' });
                    }
                }
    
                return {
                    ...verifyRow,
                    discrepancies,
                };
            })
            .filter((row) => row.discrepancies.length > 0);
    };
    
    
    const handleResend = (application_id) => {
        // Logic to resend the application, perhaps triggering a backend route
        alert(`Resend triggered for application: ${application_id}`);
    };

    const handleArchive = async (application_id) => {

        const confirmArchive = window.confirm('Are you sure you want to archive this application?');
        if (!confirmArchive) {
            return; // If the user cancels, do nothing
        }

        try {
            // Log before making the request

            // Send a PUT request to the backend to archive the application
            const response = await fetch(
                `https://ariaslogin-4a95935f6093.herokuapp.com/api/verify/archive`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ application_id }), // Send the application_id in the request body
                }
            );

            // Log the response status

            const result = await response.json();

            if (result.success) {
                alert('Application archived successfully!');
                // Update the local state to mark the application as archived (archive = 'y')
                setVerifyData((prevData) =>
                    prevData.map((item) =>
                        item.application_id === application_id ? { ...item, archive: 'y' } : item
                    )
                );
            } else {
                alert(result.message || 'Failed to archive the application.');
            }
        } catch (error) {
            console.error('Error archiving application:', error);
            alert('An error occurred while archiving the application.');
        }
    };

    const updateArchiveStatus = (application_id, archiveStatus) => {
        setVerifyData(prevData => 
            prevData.map(item =>
                item.application_id === application_id
                    ? { ...item, archive: archiveStatus }
                    : item
            )
        );
    };

    const renderSeniorMedicalQuestions = (row, clientRow) => {
        const seniorMedicalQuestions = [
            { agentKey: 'senior_rejected_answer', clientKey: 'senior_rejected' },
            { agentKey: 'heart_lung_answer', clientKey: 'heart_lung' },
            { agentKey: 'cirrhosis_answer', clientKey: 'cirrhosis' },
            { agentKey: 'amputation_answer', clientKey: 'amputation' },
            { agentKey: 'cancer_senior_answer', clientKey: 'cancer_senior' },
            { agentKey: 'oxygen_answer', clientKey: 'oxygen' },
        ];

        return (
            <table className='recruiting-table'>
                <thead>
                    <tr>
                        <th>Question</th>
                        <th>Agent Answer</th>
                        {clientRow && <th>Client Answer</th>} {/* Only show Client Answer if clientRow exists */}
                    </tr>
                </thead>
                <tbody>
                    {seniorMedicalQuestions.map(({ agentKey, clientKey }, index) => {
                        const agentAnswer = row[agentKey] === 'n' ? 'No' : 'Yes';
                        const clientAnswer = clientRow ? (clientRow[clientKey] === 'n' ? 'No' : 'Yes') : 'N/A';

                        const fullQuestion = questionTooltips[clientKey] || 'No tooltip available';
                        const hasMismatch = clientRow && agentAnswer !== clientAnswer;

                        return (
                            <tr key={index} style={{ backgroundColor: hasMismatch ? 'lightcoral' : 'white' }}>
                                <td title={fullQuestion}>{capitalizeWords(clientKey)}</td>
                                <td>{agentAnswer}</td>
                                {clientRow && <td>{clientAnswer}</td>} {/* Only render Client Answer if clientRow exists */}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    };

    const renderSuperComboMedicalQuestions = (row, clientRow) => {
        const superComboMedicalQuestions = [
            { agentKey: 'anxiety_depression_answer', clientKey: 'anxiety_depression' },
            { agentKey: 'cancer_answer', clientKey: 'cancer' },
            { agentKey: 'chronic_illness_answer', clientKey: 'chronic_illness' },
            { agentKey: 'diabetes_answer', clientKey: 'diabetes' },
            { agentKey: 'dui_answer', clientKey: 'dui' },
            { agentKey: 'er_visit_answer', clientKey: 'er_visit' },
            { agentKey: 'heart_issues_answer', clientKey: 'heart_issues' },
            { agentKey: 'high_blood_pressure_answer', clientKey: 'high_blood_pressure' },
            { agentKey: 'medications_answer', clientKey: 'medications' },
        ];

        return (
            <table className='recruiting-table'>
                <thead>
                    <tr>
                        <th>Question</th>
                        <th>Agent Answer</th>
                        {clientRow && <th>Client Answer</th>} {/* Only show Client Answer if clientRow exists */}
                    </tr>
                </thead>
                <tbody>
                    {superComboMedicalQuestions.map(({ agentKey, clientKey }, index) => {
                        const agentAnswer = row[agentKey] === 'n' ? 'No' : 'Yes';
                        const clientAnswer = clientRow ? (clientRow[clientKey] === 'n' ? 'No' : 'Yes') : 'N/A';

                        const fullQuestion = questionTooltips[clientKey] || 'No tooltip available';
                        const hasMismatch = clientRow && agentAnswer !== clientAnswer;

                        return (
                            <tr key={index} style={{ backgroundColor: hasMismatch ? 'lightcoral' : 'white' }}>
                                <td title={fullQuestion}>{capitalizeWords(clientKey)}</td>
                                <td>{agentAnswer}</td>
                                {clientRow && <td>{clientAnswer}</td>} {/* Only render Client Answer if clientRow exists */}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    };

    const handleQueuedRowClick = (row) => {
        setSelectedQueuedRow(row);
    };
    const handleCloseQueuedDetails = () => {
        setSelectedQueuedRow(null);
    };
    
    // Functions to handle selection of rows in each tab
    const handleUnverifiedRowClick = (row) => {
        setSelectedUnverifiedRow(row);
    };

    const handleVerifiedRowClick = (row) => {
        setSelectedVerifiedRow(row);
    };

    const handleDiscrepancyRowClick = (row) => {
        setSelectedDiscrepancyRow(row);
    };

    // Functions to close details and return to table view
    const handleCloseUnverifiedDetails = () => {
        setSelectedUnverifiedRow(null);
    };

    const handleCloseVerifiedDetails = () => {
        setSelectedVerifiedRow(null);
    };

    const handleCloseDiscrepancyDetails = () => {
        setSelectedDiscrepancyRow(null);
    };

    const getArchivedUnverifiedData = () => {
        return verifyData.filter(
            (verifyRow) => 
                !verifyClientData.some((clientRow) => clientRow.application_id === verifyRow.application_id) &&
                verifyRow.archive === 'y' // Only archived applications
        );
    };
    

    const getArchivedVerifiedData = () => {
        return verifyData.filter((verifyRow) => {
            const matchingClientRow = verifyClientData.find((clientRow) => clientRow.application_id === verifyRow.application_id);
    
            if (matchingClientRow) {
                // Define medical questions keys
                const medicalKeys = [
                    'amputation', 'anxiety_depression', 'cancer', 'cancer_senior', 'chronic_illness', 
                    'cirrhosis', 'diabetes', 'dui', 'er_visit', 'heart_issues', 'heart_lung', 
                    'high_blood_pressure', 'medications', 'oxygen', 'senior_rejected'
                ];
    
                // Allow rows where answers match exactly, whether both are "n" or matching "yes(insureds)"
                const allMedicalMatch = !medicalKeys.some((key) => {
                    const agentAnswer = verifyRow[`${key}_answer`];
                    const clientAnswer = matchingClientRow[key];
                    return agentAnswer !== clientAnswer;
                });
    
                const noVerificationIssues = (
                    matchingClientRow.account_verification === 'y' &&
                    matchingClientRow.application_verification === 'y' &&
                    matchingClientRow.agent_contact_request.toLowerCase() === 'no'
                );
    
                const ipAddressesMatch = verifyRow.agent_ip === matchingClientRow.client_ip;
    
                return allMedicalMatch && noVerificationIssues && !ipAddressesMatch && verifyRow.archive === 'y';
            }
    
            return false;
        });
    };
    

    const getArchivedDiscrepancyData = () => {
        return verifyData
            .filter((verifyRow) => verifyRow.archive === 'y')
            .map((verifyRow) => {
                const matchingClientRow = verifyClientData.find(
                    (clientRow) => clientRow.application_id === verifyRow.application_id
                );
                const discrepancies = [];
    
                if (matchingClientRow) {
                    const medicalKeys = [
                        'amputation', 'anxiety_depression', 'cancer', 'cancer_senior', 'chronic_illness', 
                        'cirrhosis', 'diabetes', 'dui', 'er_visit', 'heart_issues', 'heart_lung', 
                        'high_blood_pressure', 'medications', 'oxygen', 'senior_rejected'
                    ];
    
                    medicalKeys.forEach((key) => {
                        const agentAnswer = verifyRow[`${key}_answer`] || 'n';
                        const clientAnswer = matchingClientRow[key] || 'n';
    
                        // Extract insured names from 'yes(insureds)' format for both agent and client answers
                        const agentInsureds = agentAnswer.toLowerCase().includes('yes(')
                            ? agentAnswer.match(/\(([^)]+)\)/)[1].split(',').map(name => name.trim().toLowerCase())
                            : [];
    
                        const clientInsureds = clientAnswer.toLowerCase().includes('yes(')
                            ? clientAnswer.match(/\(([^)]+)\)/)[1].split(',').map(name => name.trim().toLowerCase())
                            : [];
    
                        // Determine if there is a mismatch for specific insureds
                        const insuredMismatch = agentInsureds.some(insured => !clientInsureds.includes(insured)) ||
                                                clientInsureds.some(insured => !agentInsureds.includes(insured));
    
                        if ((agentAnswer !== clientAnswer && !agentInsureds.length && !clientInsureds.length) || insuredMismatch) {
                            discrepancies.push({ question: key, agentAnswer, clientAnswer });
                        }
                    });
    
                    // Check other fields for discrepancies
                    if (matchingClientRow.account_verification === 'n') {
                        discrepancies.push({ question: 'account_verification', issue: 'Account verification failed' });
                    }
                    if (matchingClientRow.application_verification === 'n') {
                        discrepancies.push({ question: 'application_verification', issue: 'Application verification failed' });
                    }
                    if (matchingClientRow.agent_contact_request.toLowerCase() !== 'no') {
                        discrepancies.push({ question: 'agent_contact_request', issue: 'Agent contact request present' });
                    }
                    if (verifyRow.agent_ip === matchingClientRow.client_ip) {
                        discrepancies.push({ question: 'IP Match', issue: 'IP addresses match' });
                    }
                }
    
                return {
                    ...verifyRow,
                    discrepancies,
                };
            })
            .filter((row) => row.discrepancies.length > 0);
    };
    return (
        <div className='app-container'>
            <div className='large-app-container'>
                <h1></h1>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
    <button
        className='insured-button'
        onClick={handleRefresh}
        style={{ backgroundColor: '#4CAF50', color: 'white' }}
    >
        Refresh
    </button>
    <div>
        <button
            className='insured-button'
            onClick={() => setIsArchiveView(false)}
            style={{
                marginRight: '10px',
                backgroundColor: isArchiveView ? '#d1d5db' : '#4CAF50',
                color: 'white',
            }}
        >
            Current Applications
        </button>
        <button
            className='insured-button'
            onClick={() => setIsArchiveView(true)}
            style={{ backgroundColor: isArchiveView ? '#4CAF50' : '#d1d5db', color: 'white' }}
        >
            Archived Applications
        </button>
    </div>
</div>

                {loading ? (
                    <Placeholder />
                ) : error ? (
                    <p style={{ color: 'red' }}>{error}</p>
                ) : (
<Tabs selectedIndex={selectedTabIndex} onSelect={handleTabSelect}>
<TabList>
    {!isArchiveView && <Tab>Queued ({getQueuedData().length})</Tab>} {/* Conditionally render Queued Tab */}
    <Tab>Unverified ({getUnverifiedData().length})</Tab>
    <Tab>Verified ({getVerifiedData().length})</Tab>
    <Tab>Discrepancy ({getDiscrepancyData().length})</Tab>
</TabList>

{/* Queued Tab */}
<TabPanel>
    {selectedQueuedRow ? (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <p>
                    <span
                        style={{ cursor: 'pointer', color: 'blue' }}
                        onClick={handleCloseQueuedDetails}
                    >
                        {isArchiveView ? 'Archived Queued Applications' : 'Queued Applications'}
                    </span>{' '}
                    &gt; {selectedQueuedRow.client_name}
                </p>
                <div>
                    <button
                        className='insured-button'
                        onClick={() => handleArchive(selectedQueuedRow.application_id, true)} // Archive
                    >
                        Archive
                    </button>
                </div>
            </div>
            <ApplicationDetails
                row={selectedQueuedRow}
                clientData={null} // No client data for queued
                parseInsuredInfo={parseInsuredInfo}
                renderMedicalQuestions={(row, insuredType) =>
                    insuredType === 'Senior'
                        ? renderSeniorMedicalQuestions(row, null)
                        : renderSuperComboMedicalQuestions(row, null)
                }
                isQueuedTab={true} 
            />
        </>
    ) : (
        <ApplicationTable
            data={ getQueuedData()} // Use getQueuedData to populate the table
            onRowClick={handleQueuedRowClick}
            getSaleType={getSaleType}
            isArchiveView={isArchiveView}
            updateArchiveStatus={updateArchiveStatus}
            isQueuedTab={true} 
        />
    )}
</TabPanel>

                        {/* Unverified Tab */}
                        <TabPanel>
                            {selectedUnverifiedRow ? (
                                <>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <p>
                                            <span
                                                style={{ cursor: 'pointer', color: 'blue' }}
                                                onClick={handleCloseUnverifiedDetails}
                                            >
                                                {isArchiveView ? 'Archived Unverified Applications' : 'Unverified Applications'}
                                            </span>{' '}
                                            &gt; {selectedUnverifiedRow.client_name}
                                        </p>
                                        <div>
                                            <button
                                                className='insured-button'
                                                onClick={() => handleArchive(selectedUnverifiedRow.application_id, true)} // Archive
                                            >
                                                Archive
                                            </button>
                                        </div>
                                    </div>
                                    <ApplicationDetails
                                        row={selectedUnverifiedRow}
                                        clientData={null} // No client data for unverified
                                        parseInsuredInfo={parseInsuredInfo}
                                        renderMedicalQuestions={(row, insuredType) =>
                                            insuredType === 'Senior'
                                                ? renderSeniorMedicalQuestions(row, null)
                                                : renderSuperComboMedicalQuestions(row, null)
                                        }
                                    />
                                </>
                            ) : (
<ApplicationTable
    data={isArchiveView ? getArchivedUnverifiedData() : getUnverifiedData()}
    onRowClick={handleUnverifiedRowClick}
    getSaleType={getSaleType}
    isArchiveView={isArchiveView}
    updateArchiveStatus={updateArchiveStatus}
/>

                            )}
                        </TabPanel>

                        {/* Verified Tab */}
                        <TabPanel>
                            {selectedVerifiedRow ? (
                                <>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <p>
                                            <span
                                                style={{ cursor: 'pointer', color: 'blue' }}
                                                onClick={handleCloseVerifiedDetails}
                                            >
                                                {isArchiveView ? 'Archived Verified Applications' : 'Verified Applications'}
                                            </span>{' '}
                                            &gt; {selectedVerifiedRow.client_name}
                                        </p>
                                        <div>
                                            <button
                                                className='insured-button'
                                                onClick={() => handleArchive(selectedVerifiedRow.application_id, true)} // Archive
                                            >
                                                Archive
                                            </button>
                                        </div>
                                    </div>
                                    <ApplicationDetails
                                        row={selectedVerifiedRow}
                                        clientData={getClientVerifyData(selectedVerifiedRow.application_id)}
                                        parseInsuredInfo={parseInsuredInfo}
                                        renderMedicalQuestions={(row, insuredType, clientRow) =>
                                            insuredType === 'Senior'
                                                ? renderSeniorMedicalQuestions(row, clientRow)
                                                : renderSuperComboMedicalQuestions(row, clientRow)
                                        }
                                    />
                                </>
                            ) : (
<ApplicationTable
    data={isArchiveView ? getArchivedVerifiedData() : getVerifiedData()}
    onRowClick={handleVerifiedRowClick}
    getSaleType={getSaleType}
    isArchiveView={isArchiveView}
    updateArchiveStatus={updateArchiveStatus}
    isVerifiedTab={true}
/>
                            )}
                        </TabPanel>

                        {/* Discrepancy Tab */}
                        <TabPanel>
                            {selectedDiscrepancyRow ? (
                                <>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <p>
                                            <span
                                                style={{ cursor: 'pointer', color: 'blue' }}
                                                onClick={handleCloseDiscrepancyDetails}
                                            >
                                                {isArchiveView ? 'Archived Discrepancy Applications' : 'Discrepancy Applications'}
                                            </span>{' '}
                                            &gt; {selectedDiscrepancyRow.client_name}
                                        </p>
                                        <div>
                                            <button
                                                className='insured-button'
                                                onClick={() => handleArchive(selectedDiscrepancyRow.application_id, true)} // Archive
                                            >
                                                Archive
                                            </button>
                                        </div>
                                    </div>
                                    <ApplicationDetails
                                        row={selectedDiscrepancyRow}
                                        clientData={getClientVerifyData(selectedDiscrepancyRow.application_id)}
                                        parseInsuredInfo={parseInsuredInfo}
                                        isDiscrepancyTab={true}
                                        renderMedicalQuestions={(row, insuredType, clientRow) =>
                                            insuredType === 'Senior'
                                                ? renderSeniorMedicalQuestions(row, clientRow)
                                                : renderSuperComboMedicalQuestions(row, clientRow)
                                        }
                                    />
                                </>
                            ) : (
<ApplicationTable
    data={isArchiveView ? getArchivedDiscrepancyData() : getDiscrepancyData()}
    onRowClick={handleDiscrepancyRowClick}
    getSaleType={getSaleType}
    isArchiveView={isArchiveView}
    updateArchiveStatus={updateArchiveStatus}
    isDiscrepancyTab={true}
    verifyClientData={verifyClientData}
/>

                            )}
                        </TabPanel>
                    </Tabs>
                )}
            </div>
        </div>
    );
}

export default AdminVerify;
