import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const EmailViewer = ({ row }) => {
  const [emails, setEmails] = useState({ inbox: [], sent: [] });
  const [emailLogs, setEmailLogs] = useState([]); // Store all logs
  const [selectedEmailId, setSelectedEmailId] = useState(null); // State to store the ID of the selected email
  const [showForwardInput, setShowForwardInput] = useState(false); // State for showing forward input
  const [forwardEmail, setForwardEmail] = useState(''); // State for forward email input
  const [loading, setLoading] = useState(true); // State to track loading
  const [selectedEmailIds, setSelectedEmailIds] = useState([]); // Array to store IDs of expanded emails

  // Fetch all email logs
  const fetchEmailLogs = async () => {
    try {
      const response = await axios.get('https://ariaslogin-4a95935f6093.herokuapp.com/api/email-logs');
      setEmailLogs(response.data.data);
    } catch (error) {
      console.error('Error fetching email logs:', error);
    }
  };

  // Fetch emails when the component mounts
  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const response = await axios.get('https://ariaslogin-4a95935f6093.herokuapp.com/api/emails');
        console.log("Fetched emails data:", response.data); // Log incoming data
        setEmails(response.data); // Set emails
      } catch (error) {
        console.error('Error fetching emails:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEmails();
    fetchEmailLogs(); // Fetch logs as well
}, []);


  // Match emails with logs based on message_id (emails) and email_id (logs)
  const getEmailLog = (messageId) => {
    return emailLogs.find(log => log.email_id === messageId);
  };

  const filterIncomingEmails = () => {
    return emails.inbox.filter((email) => {
      const fromAddress = email?.from?.split('<')[1]?.replace('>', '').trim(); // Extract the actual email from the "from" field
      return fromAddress === row.client_email;
    });
  };

  const filterSentEmails = () => {
    return emails.sent.filter((email) => {
      const toAddresses = email?.to?.split(',').map((to) => to.trim());
      return toAddresses?.includes(row.client_email);
    });
  };

  const handleRowClick = (emailId) => {
    setSelectedEmailIds((prevSelectedIds) =>
      prevSelectedIds.includes(emailId)
        ? prevSelectedIds.filter((id) => id !== emailId)
        : [...prevSelectedIds, emailId]
    );
  };
  

  const handleForwardClick = () => {
    setShowForwardInput(!showForwardInput); // Toggle showing the forward input
  };

  const handleSendForward = async (email) => {
    if (forwardEmail) {
      const forwardBody = `
        <p>Forwarded message:</p>
        <p><strong>From:</strong> ${email.from}</p>
        <p><strong>To:</strong> ${row.client_email}</p>
        <p><strong>Subject:</strong> ${email.subject}</p>
        <hr/>
        ${email.html || email.text}
      `;

      try {
        const response = await axios.post('https://ariaslogin-4a95935f6093.herokuapp.com/api/forwardEmail', {
          to: forwardEmail,
          subject: `Fwd: ${email.subject}`,
          body: forwardBody,
        });

        if (response.data.success) {
          alert(`Email forwarded to ${forwardEmail}`);
          setForwardEmail('');
          setShowForwardInput(false);
        } else {
          alert('Failed to forward the email.');
        }
      } catch (error) {
        console.error('Error forwarding email:', error);
        alert('Error forwarding email.');
      }
    }
  };

  const renderEmailBody = (email) => {
    if (email.html) {
      return (
        <div
          className="email-body"
          dangerouslySetInnerHTML={{ __html: email.html }}
        />
      );
    }
    return <pre className="email-body">{email.text}</pre>;
  };

  const renderEmailRows = (emailsToRender, type) => {
    if (loading) {
      return (
        <tr>
          <td colSpan="3" style={{ textAlign: 'center' }}>
            <span>Loading...</span>
          </td>
        </tr>
      );
    }
  
    if (emailsToRender.length === 0) {
      return (
        <tr>
          <td colSpan="3" style={{ textAlign: 'center' }}>
            No {type} emails found for {row.client_email}
          </td>
        </tr>
      );
    }
  
    return emailsToRender.map((email) => {
      const emailLog = getEmailLog(email.id);
      const isEmailSelected = selectedEmailIds.includes(email.id);
  
      // Parse the date and add fallback handling
      let formattedDate;
      try {
        const parsedDate = new Date(email.date);
        formattedDate = !isNaN(parsedDate.getTime()) ? parsedDate.toLocaleString() : 'Invalid Date';
      } catch (error) {
        console.error("Error parsing date:", email.date, error);
        formattedDate = 'Invalid Date';
      }
  
      return (
        <React.Fragment key={email.id}>
          <tr onClick={() => handleRowClick(email.id)} style={{ cursor: 'pointer' }}>
            <td>{email.subject}</td>
          </tr>
          {isEmailSelected && (
            <tr style={{ backgroundColor: 'white', cursor: 'default', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>
              <td colSpan="3" style={{ position: 'relative' }}>
                <div className="email-body">
                  <h5>Subject: {email.subject}</h5>
                  <p><strong>Date:</strong> {formattedDate}</p>
                  {renderEmailBody(email)}
                </div>
              </td>
            </tr>
          )}
        </React.Fragment>
      );
    });
  };
  

  const incomingEmails = filterIncomingEmails();
  const sentEmails = filterSentEmails();

  return (
    <div>
      <div style={{ minHeight: '500px', overflow: 'auto' }}>
        <Tabs>
          <TabList>
            <Tab>Incoming</Tab>
            <Tab>Sent</Tab>
          </TabList>

          <TabPanel>
            <table className="recruiting-table">
              <thead>
                <tr>
                  <th>Subject</th>
                </tr>
              </thead>
              <tbody>{renderEmailRows(incomingEmails, 'incoming')}</tbody>
            </table>
          </TabPanel>

          <TabPanel>
            <table className="recruiting-table">
              <thead>
                <tr>
                  <th>Subject</th>
                </tr>
              </thead>
              <tbody>{renderEmailRows(sentEmails, 'sent')}</tbody>
            </table>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default EmailViewer;
