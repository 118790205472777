// src/App.js

import React, { useState, useEffect } from 'react';
import { useLocation, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Dashboard from './components/Dashboard/Dashboard';
import Production from './components/Production';
import Login from './components/Auth/Login';
import ProtectedRoute from './components/ProtectedRoute';
import Codes from './components/Codes/Codes';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AgentSetupPage from './components/Auth/AgentSetup';
import More from './components/MORE/More';
import RGaGrowth from './components/RGAGrowth/RGaGrowth';
import LoginPage from './components/Auth/LoginPage';
import Register from './components/Auth/Register';
import Account from './components/Account/Account';
import Report from './components/Report/Report';
import Compliance from './components/Recruiting/Compliance';
import Tutorials from './components/Resources/Tutorials';
import Resources from './components/Resources/Resources';
import AdminForms from './components/Admin/AdminForms';
import Calculate from './components/Calculate/Calculate';
import Pipeline from './components/Recruiting/Pipeline';
import Applicants from './components/Recruiting/Recruits/Applicants';
import Verify from './components/Verification/Verify';
import { GlobalDataProvider } from './contexts/GlobalDataContext';
import { initGA, usePageTracking } from './components/utils/analytics'; // Adjust the import path as necessary
import AdminLogin from './components/Auth/AdminLogin'; // Import AdminLogin component
import AdminNavbar from './components/Org/AdminNavbar'; // Import AdminNavbar component
import AdminVerify from './components/Org/AdminVerify';
import AdminDashboard from './components/Org/AdminDashboard';
import Release from './components/Release/Release'; // Import Release component
import { ProgressProvider } from './components/Release/ProgressContext'; // Import ProgressProvider component
import AlpSpreadsheet from './components/Report/NoTabs';
function App() {
    const [currentPage, setCurrentPage] = useState("");
    const location = useLocation();

    useEffect(() => {
        // Initialize Google Analytics on app mount
        initGA();
    }, []);

    usePageTracking(); // Track page views

    useEffect(() => {
        // Function to set the current page based on the route
        const routeToPageName = (path) => {
            switch(path) {
                case '/login': return 'LoginPage';
                case '/register': return 'Register';
                case '/agent-setup': return 'Agent Setup';
                case '/account': return 'Account'; 
                case '/': return 'Dashboard';
                case '/production': return 'Production';
                case '/codes': return 'Codes & VIPs';
                case '/more': return 'M.O.R.E.';
                case '/rga-growth': return 'Growth';
                case '/compliance': return 'Compliance'; // Add this line
                case '/reports': return 'Report Activity'; // Add this line
                case '/tutorials': return 'Tutorials'; // Add this line
                case '/admin-forms': return 'Admin Forms'; // Add this line
                case '/calculate': return 'Calculate'; // Add this line
                case '/pipeline': return 'Pipeline'; // Add this line
                case '/applicants': return 'Applicants'; // Add this line
                case '/resources': return 'Resources'; // Add this line
                case '/verify': return 'Verify'; // Add this line
                case '/release': return 'Release'; // Add this line
                case '/alp-spreadsheet': return 'ALP Spreadsheet'; // Add this line
                default: return '';
            }
        };

        setCurrentPage(routeToPageName(location.pathname)); // Set page name based on current route
    }, [location]);

    const isAuthorizedForAdminForms = () => {
        const agnName = localStorage.getItem('agnName');
        const authorizedNames = ['VANBIBBER KYLE A', 'KEEFER JAMES E'];
        return authorizedNames.includes(agnName);
    };
    
// Function to check if user has Admin role
const isAdmin = () => {
    const userRole = localStorage.getItem('userRole');
    return userRole === 'Admin';
};

const shouldShowAdminNavbar = location.pathname.startsWith('/admin/') && isAdmin();
const shouldShowMainNavbar = !location.pathname.startsWith('/adminlogin') && !shouldShowAdminNavbar;

    return (
        <GlobalDataProvider>
                <ProgressProvider>

            <>
                {shouldShowMainNavbar && <Navbar currentPage={currentPage} />}
                {shouldShowAdminNavbar && <AdminNavbar currentPage={currentPage} />}
                
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/adminlogin" element={<AdminLogin />} /> {/* No navbar for /adminlogin */}
                    <Route path="/register" element={<Register />} />
                    <Route path="/agent-setup" element={<AgentSetupPage />} />
                    <Route path="/" element={<ProtectedRoute element={Dashboard} />} />
                    <Route path="/production" element={<ProtectedRoute element={Production} />} />
                    <Route path="/codes" element={<ProtectedRoute element={Codes} />} />
                    <Route path="/more" element={<ProtectedRoute element={More} />} />
                    <Route path="/rga-growth" element={<ProtectedRoute element={RGaGrowth} />} />
                    <Route path="/compliance" element={<ProtectedRoute element={Compliance} />} />
                    <Route path="/account" element={<ProtectedRoute element={Account} />} />
                    <Route path="/reports" element={<ProtectedRoute element={Report} />} />
                    <Route path="/tutorials" element={<ProtectedRoute element={Tutorials} />} />
                    <Route path="/calculate" element={<ProtectedRoute element={Calculate} />} />
                    <Route path="/pipeline" element={<ProtectedRoute element={Pipeline} />} />
                    <Route path="/applicants" element={<ProtectedRoute element={Applicants} />} />
                    <Route path="/resources" element={<ProtectedRoute element={Resources} />} />
                    <Route path="/verify" element={<ProtectedRoute element={Verify} />} />
                    <Route path="/release" element={<ProtectedRoute element={Release} />} />
                    <Route path="/alp-spreadsheet" element={<ProtectedRoute element={AlpSpreadsheet} />} />
                    
                    {/* Protected Admin Route */}
                    <Route 
                        path="/admin/verify" 
                        element={isAdmin() ? <AdminVerify /> : <Navigate to="/adminlogin" replace />} 
                    />
                    <Route 
                        path="/admin/" 
                        element={isAdmin() ? <AdminDashboard /> : <Navigate to="/adminlogin" replace />}
                    />

                    <Route path="/admin-forms" element={isAuthorizedForAdminForms() ? <AdminForms /> : <Navigate to="/login" replace />} />
                    <Route path="*" element={<Navigate to="/login" replace />} />
                </Routes>
            </>
            </ProgressProvider>

        </GlobalDataProvider>
    );
}
export default App;
