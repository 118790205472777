// src/components/AdminForms.js

import React, { useState } from 'react';
import { Container, Collapse } from 'react-bootstrap';
import DailyActivityUpdate from './DailyActivityUpdate';
import ReportForm from './TrophyUpdate';
import AccountCreationForm from './CreateAccount';
import AllUsers from './AllUsers';
import EmailForm from './EmailForm';
import PDFUpload from './PnP';

const AdminForms = () => {
  const [showAllUsers, setShowAllUsers] = useState(false);
  const [showAccountCreationForm, setShowAccountCreationForm] = useState(false);
  const [showDailyActivityUpdate, setShowDailyActivityUpdate] = useState(false);
  const [showReportForm, setShowReportForm] = useState(false);

  const toggleSection = (sectionSetter) => {
    sectionSetter(prevState => !prevState);
  };

  return (
    <Container>
      <h1 className="mt-4">Admin Forms</h1>
<EmailForm />
      <div>
        <h3 onClick={() => toggleSection(setShowAllUsers)} style={{ cursor: 'pointer' }}>
          Active Users {showAllUsers ? 'v' : '>'}
        </h3>
        <Collapse in={showAllUsers}>
          <div id="all-users-collapse">
            <AllUsers />
          </div>
        </Collapse>
      </div>

      <div>
        <h3 onClick={() => toggleSection(setShowAccountCreationForm)} style={{ cursor: 'pointer' }}>
          Account Creation {showAccountCreationForm ? 'v' : '>'}
        </h3>
        <Collapse in={showAccountCreationForm}>
          <div id="account-creation-collapse">
            <AccountCreationForm />
          </div>
        </Collapse>
      </div>

      <div>
        <h3 onClick={() => toggleSection(setShowDailyActivityUpdate)} style={{ cursor: 'pointer' }}>
          Update Daily Activity From Old to New {showDailyActivityUpdate ? 'v' : '>'}
        </h3>
        <Collapse in={showDailyActivityUpdate}>
          <div id="daily-activity-update-collapse">
            <DailyActivityUpdate />
          </div>
        </Collapse>
      </div>

      <div>
        <h3 onClick={() => toggleSection(setShowReportForm)} style={{ cursor: 'pointer' }}>
          Manually Add Trophy Case {showReportForm ? 'v' : '>'}
        </h3>
        <Collapse in={showReportForm}>
          <div id="report-form-collapse">
            <ReportForm />
          </div>
        </Collapse>
      </div>
    </Container>
  );
};

export default AdminForms;
