import React from "react";
import "./RoleplayModal.css";

const RoleplayModal = ({ content, isOpen, onClose }) => {
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="roleplay-modal-overlay" onClick={handleOverlayClick}>
      <div className="roleplay-modal-container" onClick={(e) => e.stopPropagation()}>
        <button className="roleplay-modal-close-button" onClick={onClose}>
          &times;
        </button>
        <div className="roleplay-modal-content">
          {typeof content === "string" ? (
            <>
              <h2>{content}</h2>
              <p>Here is the detailed content or instructions for "{content}".</p>
            </>
          ) : (
            content
          )}
        </div>
      </div>
    </div>
  );
};

export default RoleplayModal;
