import React, { useEffect, useState } from "react";
import Placeholder from "../utils/Placeholder";

const AgentProgressTable = () => {
  const [agents, setAgents] = useState([]);
  const [progressData, setProgressData] = useState([]);
  const [loading, setLoading] = useState(true);
  const userId = localStorage.getItem("userId");
  const [actionStatus, setActionStatus] = useState({});

  const filteredProgressKeys = [
    "contract_2nd",
    "bonus_90d",
    "bonus_after_90d",
    "practice_pres",
    "refs_25",
  ];
  const totalProgressItems = filteredProgressKeys.length + 10 + 25;

  const calculateProgress = (responses) => {
    let completedItems = filteredProgressKeys.filter((key) => {
      if (key === "contract_2nd" || key === "bonus_90d" || key === "bonus_after_90d") {
        return (
          (key === "bonus_90d" && responses[key] === "750") ||
          (key === "bonus_after_90d" && responses[key] === "250") ||
          responses[key]
        );
      }
      return responses[key];
    }).length;

    completedItems += Math.min(responses.practice_pres || 0, 10);
    completedItems += Math.min(responses.refs_25 || 0, 25);

    return (completedItems / totalProgressItems) * 100;
  };

  const fetchAgentProgress = async (agentChecklist) => {
    return calculateProgress(agentChecklist);
  };

  const loadProgressData = async () => {
    setLoading(true);

    try {
        let agentsData;
        let validMGAs = [];

        if (userId === "101") {
            const response = await fetch(`https://ariaslogin-4a95935f6093.herokuapp.com/api/get-unreleased-users-checklist`);
            const data = await response.json();
            if (data.success) {
                agentsData = data.data;
                console.log("Unreleased users with checklist data:", agentsData);
            } else {
                console.error("Failed to fetch unreleased users with checklist data:", data.message);
                return;
            }
        } else {
            // Fetch data from searchByUserId to get the list of valid MGAs
            const response = await fetch(`https://ariaslogin-4a95935f6093.herokuapp.com/api/searchByUserId`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ userId }),
            });
            const data = await response.json();
            if (data.success) {
                validMGAs = data.data.map((agent) => agent.mga);
            } else {
                console.error("Failed to fetch agents:", data.message);
                return;
            }

            // Fetch all unreleased users with checklist data
            const checklistResponse = await fetch(`https://ariaslogin-4a95935f6093.herokuapp.com/api/get-unreleased-users-checklist`);
            const checklistData = await checklistResponse.json();
            if (checklistData.success) {
                // Filter agents whose MGA matches the valid MGAs
                agentsData = checklistData.data.filter((agent) => validMGAs.includes(agent.mga));
            } else {
                console.error("Failed to fetch unreleased users with checklist data:", checklistData.message);
                return;
            }
        }

        setAgents(agentsData);

        const agentProgressPromises = agentsData.map(async (agent) => {
            const progress = await fetchAgentProgress(agent);
            return { 
                agentName: agent.lagnname, 
                progress, 
                releaseScheduled: agent.release_scheduled, 
                timeSubmitted: agent.time_submitted,
                mga: agent.mga // Add mga here
            };
        });
      
        const progressArray = await Promise.all(agentProgressPromises);
        setProgressData(progressArray);
    } catch (error) {
        console.error("Error loading progress data:", error);
    }

    setLoading(false);
};


  useEffect(() => {
    loadProgressData();
  }, []);

  const [searchQuery, setSearchQuery] = useState('');
  const [sortConfig, setSortConfig] = useState({
    key: "agentName",
    direction: "asc"
  });

  // Separate the data into three categories
  const releaseScheduledNotNull = progressData.filter(agent => agent.releaseScheduled !== null);
// Modify this filter to include the new condition
const requestedReleaseYes = progressData.filter(agent => 
    (agent.timeSubmitted !== null && agent.releaseScheduled === null && agent.progress !== 100) || 
    (agent.progress === 100 && agent.releaseScheduled === null && agent.timeSubmitted === null)
  );
// Exclude agents with progress 100% and releaseScheduled null
const releaseScheduledNullRequestedNo = progressData.filter(agent => 
    !(agent.progress === 100 && agent.releaseScheduled === null && agent.timeSubmitted === null) &&
    agent.releaseScheduled === null && agent.timeSubmitted === null
  );
  


  const handleSort = (key) => {
    const newDirection = sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction: newDirection });
  };
  
  // Sort the data based on the selected column and direction
  const sortedData = (data) => {
    return [...data].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  };
  

// Search filter function
const filterData = (data) => {
    return data.filter((agent) =>
      agent.agentName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (agent.mga && agent.mga.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  };

  // Handle Pass/Fail actions
const handleAction = (agentName, action) => {
    setActionStatus((prevState) => ({
      ...prevState,
      [agentName]: action,
    }));
  };
  
  const handleConfirm = async (agentName) => {
    const action = actionStatus[agentName];
    // Updated this line to match the actual property name (lagnname)
    const selectedAgent = agents.find(agent => agent.lagnname === agentName);
  
    if (!selectedAgent) {
      console.error("Agent not found:", agentName);
      return;
    }
  
    console.log(`Initiating ${action} action for agentName: ${agentName}, userId: ${selectedAgent.user_id}`);
  
    try {
      const response = await fetch(
        action === "Pass" 
          ? `https://ariaslogin-4a95935f6093.herokuapp.com/api/pass-user` 
          : `https://ariaslogin-4a95935f6093.herokuapp.com/api/fail-user`, 
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: selectedAgent.user_id }), // Use selectedAgent.id for the user ID
        }
      );
  
      const data = await response.json();
      if (data.success) {
        console.log(`Successfully confirmed ${action} for agentName: ${agentName}, userId: ${selectedAgent.user_id}`);
        // Optionally update local state or re-fetch data if needed
      } else {
        console.error("Action failed:", data.message);
      }
    } catch (error) {
      console.error("Error confirming action:", error);
    }
  };
  
  

  if (loading) {
    return <Placeholder />;
  }

  return (
    <div className="agent-progress-table-container">

      {/* Search Bar */}
      <div className="search-bar">
        <input 
          type="text" 
          placeholder="Search by agent name..." 
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <h3>Upcoming Releases</h3>
<table className="recruiting-table">
  <thead>
    <tr>
      <th onClick={() => handleSort("agentName")}>
        Agent Name {sortConfig.key === "agentName" ? (sortConfig.direction === "asc" ? "↑" : "↓") : ""}
      </th>
      <th>Release Scheduled</th>
      <th onClick={() => handleSort("mga")}>
        MGA {sortConfig.key === "mga" ? (sortConfig.direction === "asc" ? "↑" : "↓") : ""}
      </th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    {sortedData(filterData(releaseScheduledNotNull)).map((agent, index) => (
      <tr key={index}>
        <td>{agent.agentName}</td>
        <td>{new Date(agent.releaseScheduled).toLocaleString()}</td>
        <td>{agent.mga || "N/A"}</td>
        <td>
          {userId === "101" && (
            actionStatus[agent.agentName] === "Pass" ? (
              <button className="insured-button" onClick={() => handleConfirm(agent.agentName)}>Confirm Pass</button>
            ) : actionStatus[agent.agentName] === "Fail" ? (
              <button className="insured-button" onClick={() => handleConfirm(agent.agentName)}>Confirm Fail</button>
            ) : (
              <>
                <button className="insured-button" onClick={() => handleAction(agent.agentName, "Pass")}>Pass</button>
                <button className="insured-button" onClick={() => handleAction(agent.agentName, "Fail")}>Fail</button>
              </>
            )
          )}
        </td>
      </tr>
    ))}
  </tbody>
</table>

<h3>Pending Scheduling</h3>
<table className="recruiting-table">
  <thead>
    <tr>
      <th onClick={() => handleSort("agentName")}>
        Agent Name {sortConfig.key === "agentName" ? (sortConfig.direction === "asc" ? "↑" : "↓") : ""}
      </th>
      <th onClick={() => handleSort("mga")}>
        MGA {sortConfig.key === "mga" ? (sortConfig.direction === "asc" ? "↑" : "↓") : ""}
      </th>
      <th>Progress (%)</th>
      <th>Requested Release</th>
    </tr>
  </thead>
  <tbody>
    {sortedData(filterData(requestedReleaseYes)).map((agent, index) => (
      <tr key={index}>
        <td>{agent.agentName}</td>
        <td>{agent.mga || "N/A"}</td>
        <td>{Math.round(agent.progress)}%</td>
        <td>{agent.timeSubmitted ? "Yes" : "No"}</td>
      </tr>
    ))}
  </tbody>
</table>

<h3>Checklist in Progress</h3>
<table className="recruiting-table">
  <thead>
    <tr>
      <th onClick={() => handleSort("agentName")}>
        Agent Name {sortConfig.key === "agentName" ? (sortConfig.direction === "asc" ? "↑" : "↓") : ""}
      </th>
      <th onClick={() => handleSort("mga")}>
        MGA {sortConfig.key === "mga" ? (sortConfig.direction === "asc" ? "↑" : "↓") : ""}
      </th>
      <th onClick={() => handleSort("progress")}>
        Progress (%) {sortConfig.key === "progress" ? (sortConfig.direction === "asc" ? "↑" : "↓") : ""}
      </th>
    </tr>
  </thead>
  <tbody>
    {sortedData(filterData(releaseScheduledNullRequestedNo)).map((agent, index) => (
      <tr key={index}>
        <td>{agent.agentName}</td>
        <td>{agent.mga || "N/A"}</td>
        <td>{Math.round(agent.progress)}%</td>
      </tr>
    ))}
  </tbody>
</table>

    </div>
  );
};

export default AgentProgressTable;
