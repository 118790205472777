import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

function AgentApplicationTable({ data, onRowClick, getSaleType, isArchiveView, updateArchiveStatus, isQueuedTab, isVerifiedTab, isDiscrepancyTab, verifyClientData }) {
    const [selectedRow, setSelectedRow] = useState(null);
    const [menuVisible, setMenuVisible] = useState(null); // Track which row has the menu open
    const [searchQuery, setSearchQuery] = useState(''); // State to track the search query
    const menuRef = useRef(null); // Reference for the popup menu
    const rowRefs = useRef([]);   // References for each row
    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
    
    const capitalizeWords = (str) => {
        return str
            .replace(/_/g, ' ') // Replace underscores with spaces
            .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize each word
    };

    const handleOptionsClick = (index, event) => {
        const rect = event.currentTarget.getBoundingClientRect(); // Get the row's position
        setMenuPosition({
            top: rect.top + window.scrollY,  // Adjust for any scroll position
            left: rect.left + window.scrollX + rect.width - 100 // Align it to the right of the row
        });
        setMenuVisible(menuVisible === index ? null : index); // Toggle menu visibility for the row
    };


    const handleResend = async (row) => {
        setMenuVisible(null); // Close the menu
        if (window.confirm(`Are you sure you want to resend for ${row.client_name}?`)) {
            try {
                // Send a POST request to the backend to trigger the resend
                const response = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/resendverify', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ application_id: row.application_id }), // Send the application ID
                });

                const result = await response.json();

                if (result.success) {
                    alert(`Application ${row.application_id} resent successfully.`);
                } else {
                    alert(`Failed to resend application: ${result.message}`);
                }
            } catch (error) {
                console.error(`Error resending application ${row.application_id}:`, error);
                alert('Error resending the application.');
            }
        }
    };


    const handleArchiveToggle = async (row) => {
        setMenuVisible(null); // Close menu

        const action = isArchiveView ? 'unarchive' : 'archive';
        const confirmationMessage = `Are you sure you want to ${action} the application for ${row.client_name}?`;

        // Confirm the user action
        if (window.confirm(confirmationMessage)) {
            try {
                // Send a PUT request to archive or unarchive the application
                const response = await fetch(`https://ariaslogin-4a95935f6093.herokuapp.com/api/verify/${action}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ application_id: row.application_id }), // Send the application ID
                });

                const result = await response.json();

                if (result.success) {
                    alert(`Application ${action}d successfully!`);

                    // Update the verifyData array with the new archive status
                    updateArchiveStatus(row.application_id, isArchiveView ? 'n' : 'y');
                } else {
                    alert(result.message || `Failed to ${action} the application.`);
                }
            } catch (error) {
                console.error(`Error ${action}ing application:`, error);
                alert(`An error occurred while trying to ${action} the application.`);
            }
        }
    };

    const handleRowClick = (row) => {
        setSelectedRow(row);
        onRowClick(row);
    };

    const convertMSTToUTC = (mstDateString) => {
        const mstDate = new Date(mstDateString);
        // Adjust for MST to UTC (UTC-7 during standard time)
        const utcDate = new Date(mstDate.getTime() + 7 * 60 * 60 * 1000); // Add 7 hours to get UTC
        return utcDate.toISOString(); // Convert to ISO string in UTC format
    };

    const convertUTCToLocalTime = (utcDateString) => {
        const utcDate = new Date(utcDateString);
        return utcDate.toLocaleString(); // Convert to local time based on user's time zone
    };


    const getSetForSendDate = (submittedDate) => {
        const submitted = new Date(submittedDate);
        const nextDay = new Date(submitted);
        nextDay.setDate(submitted.getDate() + 1); // Move to the next day
        nextDay.setHours(8, 0, 0, 0); // Set time to 8:00 AM
        return nextDay.toLocaleString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
    };

    const getDiscrepancyData = (row) => {
        if (!verifyClientData) return [];
        const matchingClientRow = verifyClientData.find(clientRow => clientRow.application_id === row.application_id);
        const discrepanciesByInsured = {};
    
        // Parse insured info from primary, spouse, and children fields
        const insureds = {
            Primary: row.primary_info,
            Spouse: row.spouse_info,
            Child1: row.child1_info,
            Child2: row.child2_info,
            Child3: row.child3_info,
            Child4: row.child4_info,
            Child5: row.child5_info,
            Child6: row.child6_info,
            Child7: row.child7_info,
            Child8: row.child8_info,
            Child9: row.child9_info
        };
    
        const medicalDiscrepancyMessages = {
            medications: "Needs medications listed on medical info sheet",
            er_visit: "Needs details of overnight hospital stay on medical info sheet",
            high_blood_pressure: "Needs High Blood Pressure Questionnaire",
            diabetes: "Needs Diabetes Questionnaire",
            cancer: "Needs Cancer Questionnaire",
            arrested: "Needs Arrest Questionnaire",
            dui: "Needs Alcohol Use, Drug, and Arrest Questionnaires",
            anxiety_depression: "Needs Depression Questionnaire",
            heart_issues: "Needs Heart/Circulatory Questionnaire",
            senior_rejected: "Was rejected for life with AIL",
            heart_lung: "Heart/Lung question discrepancy",
            cirrhosis: "Cirrhosis, Alzheimer's, ALS, dementia discrepancy",
            amputation: "Amputation question discrepancy",
            cancer_senior: "Cancer question discrepancy",
            oxygen: "Oxygen question discrepancy",
            bedridden: "Bedridden or resident of nursing home",
        };
    
        // Check each insured for discrepancies
        for (const [insuredType, insuredInfo] of Object.entries(insureds)) {
            if (insuredInfo && insuredInfo !== 'n/a') {
                const [insuredName] = insuredInfo.split(','); // Extract name of insured
                
                const insuredDiscrepancies = []; // Temporary list for discrepancies of this insured
    
                Object.keys(medicalDiscrepancyMessages).forEach((key) => {
                    const agentAnswer = row[`${key}_answer`];
                    const clientAnswer = matchingClientRow ? matchingClientRow[key] : null;
    
                    // Check if there's a discrepancy and if the answer is specific to this insured
                    if (agentAnswer !== clientAnswer && clientAnswer && clientAnswer.includes(`yes(${insuredName.toLowerCase()})`)) {
                        insuredDiscrepancies.push(
                            `- ${capitalizeWords(key)} discrepancy: Agent said ${agentAnswer === 'n' ? 'No' : 'Yes'}, Client said Yes. ${medicalDiscrepancyMessages[key]}`
                        );
                    }
                });
    
                // Add only if there are discrepancies for this insured
                if (insuredDiscrepancies.length > 0) {
                    discrepanciesByInsured[insuredName] = insuredDiscrepancies;
                }
            }
        }
    
        // Handle application-level discrepancies
        const applicationLevelDiscrepancies = [];
    
        if (matchingClientRow?.account_verification !== 'y') {
            applicationLevelDiscrepancies.push(`- Account Verification discrepancy: Client - ${matchingClientRow.account_verification}`);
        }
    
        if (matchingClientRow?.application_verification !== 'y') {
            applicationLevelDiscrepancies.push(`- Application Verification discrepancy: Client - ${matchingClientRow.application_verification}`);
        }
    
        if (matchingClientRow?.agent_contact_request !== 'No') {
            applicationLevelDiscrepancies.push(`- Agent Contact Request: ${matchingClientRow.agent_contact_request}`);
        }
    
        // Construct the final message format
        const formattedDiscrepancies = [];
        for (const [insured, messages] of Object.entries(discrepanciesByInsured)) {
            formattedDiscrepancies.push(`Insured: ${insured}\n${messages.join('\n')}`);
        }
    
        // Add application-level discrepancies at the end without a title
        formattedDiscrepancies.push(...applicationLevelDiscrepancies);
    
        return formattedDiscrepancies;
    };
    
    
    
    useEffect(() => {
        const handleClickOutside = (event) => {
            // Close the menu if the click is outside both the row and the popup menu
            if (
                menuVisible !== null &&
                menuRef.current &&
                !menuRef.current.contains(event.target) &&
                !rowRefs.current[menuVisible]?.contains(event.target)
            ) {
                setMenuVisible(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuVisible]);


    // Filter the data based on the search query
    const filteredData = data.filter((row) =>
        row.client_name.toLowerCase().includes(searchQuery) ||
        row.agent_name.toLowerCase().includes(searchQuery)
    );

    const [sortConfig, setSortConfig] = useState({ key: 'client_name', direction: 'ascending' }); // Sorting config

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedData = [...filteredData].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
    });

    return (
        <>
            {selectedRow ? (
                <div>
                    <p>
                        <span style={{ cursor: 'pointer', color: 'blue' }} onClick={() => setSelectedRow(null)}>
                            Back to Applications
                        </span> &gt; {selectedRow.client_name}
                    </p>
                    <div>
                        <h3>Details for {selectedRow.client_name}</h3>
                        {/* Display selected row details here, or pass it to another component */}
                    </div>
                </div>
            ) : (
                <>
                    {/* Search Bar */}
                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                        <input
                            type="text"
                            placeholder="Search by client name, agent name..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
                            style={{
                                padding: '8px',
                                width: '50%',
                                border: '1px solid #ccc',
                                borderRadius: '4px'
                            }}
                        />
                    </div>

                    <div style={{ overflowX: 'auto' }}>
                        <table className='recruiting-table'>
                        <thead>
    <tr>
        <th onClick={() => handleSort('agent_name')} style={{ cursor: 'pointer' }}>Agent</th>
        <th onClick={() => handleSort('client_name')} style={{ cursor: 'pointer' }}>Name</th>
        <th onClick={() => handleSort('created_at')} style={{ cursor: 'pointer' }}>Submitted</th>
        {isQueuedTab && <th onClick={() => handleSort('set_for_send')} style={{ cursor: 'pointer' }}>Queued For</th>}
        <th onClick={() => handleSort('total_annual_premium')} style={{ cursor: 'pointer' }}>ALP</th>
        <th onClick={() => handleSort('app_type')} style={{ cursor: 'pointer' }}>App Type</th>
        <th onClick={() => handleSort('status')} style={{ cursor: 'pointer' }}>Status</th>
    </tr>
</thead>

                            <tbody>
                                {sortedData.length === 0 ? (
                                    <tr>
                                        <td colSpan="6">No applications found.</td>
                                    </tr>
                                ) : (
                                    sortedData.map((row, index) => {
                                        console.log("verifyData for row:", row); // Log verifyData for each row
                                        console.log("verifyClientData for row:", verifyClientData); // Log verifyClientData for each row
                                        const utcTime = convertMSTToUTC(row.created_at); // Convert from MST to UTC
                                        const localTime = convertUTCToLocalTime(utcTime); // Convert UTC to user's local time

                                        return (
                                            <tr key={index} onClick={() => handleRowClick(row)} style={{ cursor: 'pointer' }} ref={(el) => (rowRefs.current[index] = el)} >
                                                <td>{row.agent_name}</td>
                                                <td>{row.client_name}</td>
                                                <td>{localTime}</td> {/* Display the converted local time */}
                                                {isQueuedTab && <td>{getSetForSendDate(row.created_at)}</td>}
                                                <td>{row.total_annual_premium ? row.total_annual_premium.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : ''}</td>
                                                <td>{getSaleType(row)}</td>
                                                <td>{row.status}</td>

                                            </tr>
                                        );
                                    })
                                )}
                            </tbody>


                        </table>
                    </div>
                </>
            )}
        </>
    );
}

export default AgentApplicationTable;
