import React, { useState, useEffect } from 'react';
import '../Leaderboards/Leaderboards.css'; // Use the existing styles or adjust as needed

const MoreLeaderboard = ({ moreData }) => {
    const [leaderboardData, setLeaderboardData] = useState([]);

    useEffect(() => {
        if (moreData) {
            const hiresLeaderboard = aggregateHires(moreData);
            setLeaderboardData(hiresLeaderboard);
        }
    }, [moreData]);

    const aggregateHires = (data) => {
        const aggregation = data.reduce((acc, cur) => {
            const agentName = cur.MGA; // Assuming MGA represents the agent's name
            const totalHires = parseInt(cur.Total_Hires, 10) || 0; // Replace 'Total_Hires' with the correct key if different
            const personalHires = parseInt(cur.PR_Hires, 10) || 0; // 'PR_Hires' for personal hires

            if (!acc[agentName]) {
                acc[agentName] = { agentName, totalHires, personalHires };
            } else {
                acc[agentName].totalHires += totalHires;
                acc[agentName].personalHires += personalHires;
            }
            return acc;
        }, {});

        return Object.values(aggregation).sort((a, b) => b.totalHires - a.totalHires).slice(0, 75);
    };

    const renderLeaderboardTable = (entries) => (
        <div className="leaderboard-card">
            <h5 className="card-title">Top Hires Leaderboard</h5>
            <table className="leaderboard-table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Agent Name</th>
                        <th>Total Hires</th>
                        <th>Personals</th> {/* New header for personal hires */}
                        <th className="medal-column"></th> {/* Keep the updated column for medal emojis */}
                    </tr>
                </thead>
                <tbody>
                    {entries.map((entry, index) => {
                        let medalEmoji = '';
                        if (index === 0) {
                            medalEmoji = '🥇';
                        } else if (index === 1) {
                            medalEmoji = '🥈';
                        } else if (index === 2) {
                            medalEmoji = '🥉';
                        }

                        // Determine the class for total hires based on the value
                        let hiresClass = '';
                        if (entry.totalHires >= 10) {
                            hiresClass = 'total-hires-green';
                        } else if (entry.totalHires >= 5) {
                            hiresClass = 'total-hires-yellow';
                        } else {
                            hiresClass = 'total-hires-red';
                        }

                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{entry.agentName}</td>
                                <td className={hiresClass}>{entry.totalHires}</td>
                                <td>{entry.personalHires}</td> {/* Display personal hires */}
                                <td className="medal-cell">{medalEmoji}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );

    return (
        <div className="leaderboards-section">
            {renderLeaderboardTable(leaderboardData)}
        </div>
    );
};

export default MoreLeaderboard;
