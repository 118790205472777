import React, { useState, useEffect, useRef } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './HierarchyComponent.css';
import Placeholder from '../utils/Placeholder';
import moment from 'moment';

const AdminAlpSpreadsheet = () => {
    const [data, setData] = useState([]);
    const [originalDailyActivity, setOriginalDailyActivity] = useState([]);
    const [dailyActivity, setDailyActivity] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [weeks, setWeeks] = useState([]);
    const [selectedWeekIndex, setSelectedWeekIndex] = useState(0);
    const inputRefs = useRef({}); // Reference to store input elements
    const tableContainerRef = useRef(null);
    const [officialActivity, setOfficialActivity] = useState([]); // New state for official activity
    //const [weeklyData, setWeeklyData] = useState([]); // State to store the weekly data for agents
    const [reportType, setReportType] = useState('Weekly Recap'); // State to track the selected report type

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseAllHierarchy = await axios.get('https://ariaslogin-4a95935f6093.herokuapp.com/api/allHierarchy');
                
                const reportTypes = ['Weekly Recap', 'MTD Recap', 'YTD Recap'];
                let allOfficialActivity = [];
        
                // Fetch data for each report type and store it in the allOfficialActivity array
                for (const reportType of reportTypes) {
                    const responseOfficialActivity = await axios.get('https://ariaslogin-4a95935f6093.herokuapp.com/api/getOfficialActivity', {
                        params: { REPORT: reportType }
                    });
                    if (responseOfficialActivity.data.success) {
                        const officialData = responseOfficialActivity.data.data.map(activity => ({
                            ...activity,
                            reportDate: new Date(activity.reportdate).toISOString().split('T')[0],
                            reportType
                        }));
                        allOfficialActivity = [...allOfficialActivity, ...officialData];
                    }
                }
        
                if (responseAllHierarchy.data.success) {
                    setData(responseAllHierarchy.data.data);
                    setOfficialActivity(allOfficialActivity);
                    const uniqueReportDates = getUniqueReportDates(allOfficialActivity);
                    setWeeks(uniqueReportDates);
                    setSelectedWeekIndex(0);
                } else {
                    setError('No data found');
                }
            } catch (error) {
                setError('Error fetching data');
            } finally {
                setLoading(false);
            }
        };
        
    
        fetchData();
    }, []); // Fetch once when the component mounts
    
    useEffect(() => {
        if (officialActivity.length > 0 && weeks.length > 0) {
            filterOfficialActivity(selectedWeekIndex);
        }
    }, [selectedWeekIndex, reportType]); // Filter when selectedWeekIndex or reportType changes
    
    const filterOfficialActivity = (weekIndex) => {
        const selectedDate = weeks[weekIndex]; // Get the selected reportDate
        const filteredActivity = officialActivity.filter(activity => 
            activity.reportDate === selectedDate && activity.REPORT === reportType
        ); // Filter by reportDate and reportType
        
        setDailyActivity(filteredActivity); // Update the displayed activity
    };
    
    //useEffect(() => {
      //  const fetchWeeklyData = async () => {
       //     try {
       //         const response = await axios.get('https://ariaslogin-4a95935f6093.herokuapp.com/api/groupDailyWeeks');
        
      //          if (response.data.success) {
       //             setWeeklyData(response.data.data); // Store all weekly data
      //          } else {
      //              console.error('Error fetching weekly data');
       //         }
      //      } catch (error) {
      //          console.error('Error fetching weekly data:', error);
       //     }
      //  };
    
     //   fetchWeeklyData(); // Fetch weekly data once when component mounts
   // }, []);
    

    useEffect(() => {
        const fetchHierarchyInfo = async () => {
            try {
                const userId = localStorage.getItem('userId'); // Get userId from localStorage
                const response = await axios.post('https://ariaslogin-4a95935f6093.herokuapp.com/api/getHierarchyInfo', {
                    userId,
                });
                if (response.data.success) {
                    const allowedAgents = response.data.data.map(item => item.lagnname);
                } else {
                    console.error('Failed to fetch hierarchy info');
                }
            } catch (error) {
                console.error('Error fetching hierarchy info:', error);
            }
        };

        fetchHierarchyInfo();
    }, []);

    const getUniqueWeeks = (data) => {
        const weeks = {};
        data.forEach(item => {
            const reportDate = new Date(item.reportDate); // Use reportDate from the API response
            const weekStart = moment(reportDate).startOf('isoWeek').format('MM/DD/YYYY');
            const weekEnd = moment(reportDate).endOf('isoWeek').format('MM/DD/YYYY');
            const weekKey = `${weekStart} - ${weekEnd}`;
            if (!weeks[weekKey]) {
                weeks[weekKey] = true;
            }
        });
    
        return Object.keys(weeks).sort((a, b) => new Date(b.split(' - ')[0]) - new Date(a.split(' - ')[0]));
    };

    



    const handlePreviousWeek = () => {
        if (selectedWeekIndex < weeks.length - 1) {
            const newIndex = selectedWeekIndex + 1;
            setSelectedWeekIndex(newIndex);
            filterDailyActivity(newIndex, originalDailyActivity);
        }
    };

    const handleNextWeek = () => {
        if (selectedWeekIndex > 0) {
            const newIndex = selectedWeekIndex - 1;
            setSelectedWeekIndex(newIndex);
            filterDailyActivity(newIndex, originalDailyActivity);
        }
    };

    const filterDailyActivity = (weekIndex, data = originalDailyActivity) => {
        const [mondayStr, sundayStr] = weeks[weekIndex].split(' - ');
        const monday = new Date(mondayStr);
        const sunday = new Date(sundayStr);


        const filteredActivity = data.filter(activity => {
            const reportDate = new Date(activity.reportDate);
            return reportDate >= monday && reportDate <= sunday;
        });

        setDailyActivity(filteredActivity);
    };

    const groupedData = data.reduce((acc, item) => {
        const rgaKey = item.RGA || 'No RGA';
        if (!acc[rgaKey]) {
            acc[rgaKey] = [];
        }
        acc[rgaKey].push(item);
        return acc;
    }, {});

    data.forEach((item) => {
        const mgaRGA = item.MGA;
        if (groupedData[mgaRGA]) {
            groupedData[mgaRGA].push(item);
        }
    });
    const renderDropdown = () => (
        <select onChange={handleWeekChange} value={weeks[selectedWeekIndex]}>
            {weeks.length > 0 ? weeks.map((week) => {
                // Convert the report date (Wednesday) to a moment object
                const reportDateMoment = moment(week);
    
                // Subtract one additional week to get the previous Monday and Sunday
                const previousMonday = reportDateMoment.subtract(1, 'weeks').startOf('isoWeek').format('M/D/YY');  // Previous Monday
                const previousSunday = reportDateMoment.endOf('isoWeek').format('M/D/YY');  // Previous Sunday
                
                // Create the display string for the dropdown (e.g., 9/16/24-9/22/24)
                const displayWeekRange = `${previousMonday}-${previousSunday}`;
                
                return (
                    <option value={week} key={week}>
                        {displayWeekRange} {/* Display the previous week range */}
                    </option>
                );
            }) : <option value="">No data available</option>}
        </select>
    );
    

    const renderTabs = () => {
        const sortedRGAs = Object.keys(groupedData).sort((a, b) => {
            if (a === 'No RGA') return 1; // Place 'No RGA' at the end
            if (b === 'No RGA') return -1;
            return a.localeCompare(b);
        });
    
        return sortedRGAs.map((rga) => {
            if (rga !== 'No RGA') {
                const abbreviatedName = rga.split(' ')[0];
                return (
                    <Tab eventKey={rga} title={abbreviatedName} key={rga}>
                        <MGAWithAGTs
                            mgas={groupedData[rga]}
                            dailyActivity={dailyActivity} // Use filtered daily activity here
                            officialActivity={officialActivity}
                            selectedWeek={weeks[selectedWeekIndex]} // Pass selected week
                            weeks={weeks} // Pass the weeks array
                            selectedWeekIndex={selectedWeekIndex} // Pass selectedWeekIndex
                            reportType={reportType} // Pass reportType
                            inputRefs={inputRefs}
                            tableContainerRef={tableContainerRef}
                        //    weeklyData={weeklyData}
                        />
                    </Tab>
                );
            }
            return null;
        });
    };
    
    

    const handleWeekChange = (event) => {
        const selectedReportDate = event.target.value;
        const newSelectedWeekIndex = weeks.indexOf(selectedReportDate);
    
        // Log the selected week
        
        setSelectedWeekIndex(newSelectedWeekIndex); // Only change the week index
    };
    
    const handleReportTypeChange = (newReportType) => {
        setReportType(newReportType); // Only change the report type
    };
    const getUniqueReportDates = (data) => {
        const reportDates = new Set();
        data.forEach(item => {
            const reportDate = new Date(item.reportDate).toISOString().split('T')[0];
            reportDates.add(reportDate);
        });
    
        return Array.from(reportDates).sort((a, b) => new Date(b) - new Date(a)); // Sort descending (newest first)
    };
    

    return (
        <div>
            {loading ? (
                <Placeholder />
            ) : (
                <>
                    {/* Report Type Buttons */}
                    <div className="tabs" style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
    <input
        type="radio"
        id="weekly-recap"
        name="report_type"
        value="Weekly Recap"
        checked={reportType === 'Weekly Recap'}
        onChange={() => handleReportTypeChange('Weekly Recap')}
    />
    <label htmlFor="weekly-recap" className={`report-btn ${reportType === 'Weekly Recap' ? 'active' : ''}`} style={{ }}>
        Weekly Recap
    </label>

    <input
        type="radio"
        id="mtd-recap"
        name="report_type"
        value="MTD Recap"
        checked={reportType === 'MTD Recap'}
        onChange={() => handleReportTypeChange('MTD Recap')}
    />
    <label htmlFor="mtd-recap" className={`report-btn ${reportType === 'MTD Recap' ? 'active' : ''}`} style={{ }}>
        MTD Recap
    </label>

    <input
        type="radio"
        id="ytd-recap"
        name="report_type"
        value="YTD Recap"
        checked={reportType === 'YTD Recap'}
        onChange={() => handleReportTypeChange('YTD Recap')}
    />
    <label htmlFor="ytd-recap" className={`report-btn ${reportType === 'YTD Recap' ? 'active' : ''}`} style={{ }}>
        YTD Recap
    </label>
</div>

    
                    {/* Report Date Dropdown */}
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                        <button className='hier-change-week-left' onClick={handlePreviousWeek} disabled={selectedWeekIndex === weeks.length - 1}>{'<'}</button>
                        {renderDropdown()} {/* Use the updated dropdown rendering */}
                        <button className='hier-change-week-right' onClick={handleNextWeek} disabled={selectedWeekIndex === 0}>{'>'}</button>
                    </div>
    
                    {/* Tabbed Data Display */}
                    <Tabs defaultActiveKey={Object.keys(groupedData).find(rga => rga !== 'No RGA')} id="rga-tabs">
                        {renderTabs()}
                    </Tabs>
                </>
            )}
        </div>
    );
    

};

const reformatAgentName = (name) => {
    if (!name) return ''; // Check if name is undefined or null
    const parts = name.split(' ');
    if (parts.length < 2) return name;
    const [last, first, ...rest] = parts;
    return `${first} ${rest.join(' ')} ${last}`;
};
//repass weeklydata if start using again
const MGAWithAGTs = ({ mgas, dailyActivity, selectedWeek, editingCell, formData, handleCellClick, handleInputChange, handleSave, handleCancel, inputRefs, editingRowRef, tableContainerRef, officialActivity, weeks, selectedWeekIndex, reportType,  }) => {


    const getLVL1NetForAgent = (lagnname) => {
        // Get the selected week (report date) and report type from the props
        const selectedDate = weeks[selectedWeekIndex]; // Get the selected report date
        const selectedReportType = reportType; // Get the selected report type (Weekly Recap, MTD Recap, YTD Recap)
        
        // Find the matching official activity based on LagnName, reportDate, and REPORT
        const officialRecord = officialActivity.find(activity => 
            activity.LagnName === lagnname &&
            activity.reportDate === selectedDate && // Ensure the date matches the selected week
            activity.REPORT === selectedReportType // Ensure it matches the selected report type
        );
    
        // If a match is found, return LVL_1_GROSS, otherwise return 'N/A'
        return officialRecord ? officialRecord.LVL_1_NET : 'N/A';
    };
    
    
    const getLVL1GrossForAgent = (lagnname) => {
        const selectedDate = weeks[selectedWeekIndex];
        const selectedReportType = reportType;

        // Find matching official activity based on LagnName, reportDate, and REPORT type
        const officialRecord = officialActivity.find(activity => 
            activity.LagnName === lagnname &&
            activity.reportDate === selectedDate && 
            activity.REPORT === selectedReportType
        );
        
        return officialRecord ? officialRecord.LVL_1_GROSS : 'N/A';
    };
    const calculateNetToGross = (net, gross) => {
        if (gross === 0 || gross === 'N/A') {
            return 'N/A';
        }
        return (net / gross).toFixed(2); // Calculate the ratio, rounded to 2 decimals
    };

    const getTotalALPForAgent = (agentName) => {
        const selectedWeekDate = weeks[selectedWeekIndex]; // Get the selected week (e.g., 2024-09-25)
        const reportDateMoment = moment(selectedWeekDate); // Convert the selected date to a moment object
    
        // Calculate the Monday and Sunday of the week containing the selected date
        const previousMonday = reportDateMoment.subtract(1, 'weeks').startOf('isoWeek').format('MM/DD/YYYY'); // Monday
        const previousSunday = reportDateMoment.endOf('isoWeek').format('MM/DD/YYYY'); // Sunday
        
        // Create the week range string (e.g., "09/16/2024-09/22/2024")
        const calculatedWeekRange = `${previousMonday}-${previousSunday}`;
    
        // Log the week range that will be used for filtering
    
        // Now filter the weeklyData using the calculated week range
     //   const weeklyRecord = weeklyData.find((entry) => entry.agent === agentName && entry.week_range === calculatedWeekRange);
    
        // Log the matching weekly record, if found
    
      //  return weeklyRecord ? weeklyRecord.total_alp : 'N/A'; // Return 'N/A' if no data is found
    };
    
    
    const isWithinFirstThreeMonths = (esid) => {
        const startDate = getStartDateFromEsid(esid); // Implement this based on how the esid stores the start date
        const now = moment();
        const startMoment = moment(startDate);
        const monthsDiff = now.diff(startMoment, 'months');
        return monthsDiff < 3;
    };
    const getStartDateFromEsid = (esid) => {
        // Extract the start date from esid if it's part of the esid or get it from a separate data source
        // Placeholder example (adjust this logic according to your actual esid structure):
        return moment(esid, 'YYYY-MM-DD'); // Assuming esid contains a date in this format
    };

    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    const buildHierarchy = (data) => {
        const hierarchy = [];
        const map = {};
        const inactiveNodes = [];

        // Initialize map with each item
        data.forEach(item => {
            map[item.lagnname] = { ...item, children: [] };
        });

        // Group children under their respective parents based on the hierarchy rules
        data.forEach(item => {
            if (item.managerActive === 'n') {
                inactiveNodes.push(map[item.lagnname]);
                return;
            }

            if (item.sa && map[item.sa]) {
                map[item.sa].children.push(map[item.lagnname]);
            } else if (item.ga && map[item.ga]) {
                map[item.ga].children.push(map[item.lagnname]);
            } else if (item.mga && map[item.mga]) {
                map[item.mga].children.push(map[item.lagnname]);
            } else if (item.rga && map[item.rga]) {
                map[item.rga].children.push(map[item.lagnname]);
            } else {
                hierarchy.push(map[item.lagnname]);
            }
        });

        // Ensure the hierarchy follows the specified order
        const sortHierarchy = (nodes) => {
            return nodes.sort((a, b) => {
                const order = ['RGA', 'MGA', 'GA', 'AGT', 'SA'];
                const orderA = order.indexOf(a.clname);
                const orderB = order.indexOf(b.clname);

                // Custom order for AGT with null sa and non-null ga before SA with null sa and non-null ga
                if (orderA === orderB) {
                    if (a.clname === 'AGT' && b.clname === 'SA') {
                        if (a.sa === null && b.sa === null && a.ga !== null && b.ga !== null) {
                            return -1;
                        }
                    } else if (a.clname === 'SA' && b.clname === 'AGT') {
                        if (a.sa === null && b.sa === null && a.ga !== null && b.ga !== null) {
                            return 1;
                        }
                    }
                    return a.managerActive === 'n' ? 1 : -1;
                }

                return orderA - orderB;
            }).map(node => {
                node.children = sortHierarchy(node.children);
                return node;
            });
        };

        const sortedHierarchy = sortHierarchy(hierarchy);
        const sortedInactiveNodes = sortHierarchy(inactiveNodes);


        return { sortedHierarchy, sortedInactiveNodes };
    };

    const getColorForClname = (clname) => {
        switch (clname) {
            case 'RGA':
                return 'lightblue';
            case 'MGA':
                return '#68B675';
            case 'GA':
                return '#ED722F';
            case 'AGT':
                return 'black';
            case 'SA':
                return '#B25271';
            default:
                return 'black';
        }
    };

    const getFontWeightForClname = (clname) => {
        switch (clname) {
            case 'MGA':
            case 'GA':
            case 'SA':
                return 'bold';
            default:
                return 'normal';
        }
    };

    const loggedInAgentName = localStorage.getItem('agnName'); // Retrieve logged-in agent's name


    const renderAgents = (agent) => {
        const clnameColor = getColorForClname(agent.clname);
        const clnameFontWeight = getFontWeightForClname(agent.clname);
        const isLoggedInAgent = agent.lagnname === loggedInAgentName;
        const isNewAgent = isWithinFirstThreeMonths(agent.esid);
    
        const lvl1Net = getLVL1NetForAgent(agent.lagnname);
        const lvl1Gross = getLVL1GrossForAgent(agent.lagnname);
        const netToGross = calculateNetToGross(lvl1Net, lvl1Gross);
        const totalALP = getTotalALPForAgent(agent.lagnname);
    
        // Format the `esid` date to `MM/DD/YY`
        const formattedEsid = moment(agent.esid).format('MM/DD/YY');
    
        // Format LVL_1_NET and LVL_1_GROSS as currency
        const formattedLvl1Net = lvl1Net !== 'N/A' ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(lvl1Net) : 'N/A';
        const formattedLvl1Gross = lvl1Gross !== 'N/A' ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(lvl1Gross) : 'N/A';
    
        // Format `netToGross` as a percentage
        const formattedNetToGross = netToGross !== 'N/A' ? `${(netToGross * 100).toFixed(2)}%` : 'N/A';
    
        return (
            <React.Fragment key={agent.lagnname}>
                <tr
                    id={`row-${agent.lagnname}`}
                    ref={editingCell && editingCell.lagnname === agent.lagnname ? editingRowRef : null}
                    className={`${editingCell && editingCell.lagnname === agent.lagnname ? 'editing-row' : ''} ${isNewAgent ? 'new-agent' : ''}`}
                    style={{ backgroundColor: isNewAgent ? 'yellow' : '' }}
                >
                    <td style={{ color: clnameColor, fontWeight: clnameFontWeight }}>{agent.additionalDetails}</td>
                    <td
                        className="first-column"
                        style={{
                            backgroundColor: isNewAgent
                                ? 'black'
                                : isLoggedInAgent
                                    ? '#ED722F'
                                    : '#00548C',
                        }}
                    >
                        {reformatAgentName(agent.lagnname)}
                    </td>
                    <td>{formattedEsid}</td> {/* Display the formatted esid */}
                   {/* {reportType === 'Weekly Recap' && <td>{totalALP}</td>} */}
                    <td>{formattedLvl1Gross}</td> {/* Display LVL_1_GROSS as currency */}
                    <td>{formattedLvl1Net}</td> {/* Display LVL_1_NET as currency */}
                    <td>{formattedNetToGross}</td> {/* Display NET_TO_GROSS as percentage */}
                </tr>
                {agent.children && agent.children.map(subordinate => renderAgents(subordinate))}
            </React.Fragment>
        );
    };
    
    
    return (
        <div className="hier-table-container" ref={tableContainerRef}>
            {mgas.map((mga) => {
                // Split the AGTs string into individual agent strings
                const parts = mga.AGTs.split('; ');
                const agents = parts.map((part, index) => {
                    if (index === 0) {
                        // Handle the first part of AGTs, which is the MGA details
                        const [lagnname, agtnum, esid, clname] = part.split(' - ');
                        return { lagnname, agtnum, esid, clname: 'MGA', additionalDetails: 'MGA' };
                    } else {
                        // Handle subsequent parts of AGTs, which are agent details
                        return part.split('-- ').map(agt => {
                            const [lagnname, agtnum, esid, sa, ga, mga, clname] = agt.split(' - ');
                            return { lagnname, agtnum, esid, sa, ga, mga, clname, additionalDetails: clname };
                        });
                    }
                }).flat();

                const { sortedHierarchy } = buildHierarchy(agents);

                return (
                    <div key={mga.MGA}>
                        <table className="hierarchyTable">
                        <thead>
    <tr className="first-row">
        <th className='day-header'></th>
        <th className='day-header'>
            <h5 className='hier-mga-header'>
                {mga.MGA.split(' ')[0]}
            </h5>
        </th>
        <th className='day-header'>Hire Date</th>
       {/* {reportType === 'Weekly Recap' && <th className='day-header'>Reported</th>} */}
        <th className='day-header'>LVL 1 GROSS</th>
        <th className='day-header'>LVL 1 NET</th>
        <th className='day-header'>NET TO GROSS</th>
    </tr>
</thead>

                            <tbody>
                                {sortedHierarchy.map(agent => renderAgents(agent))}
                            </tbody>
                        </table>
                    </div>
                );
            })}
        </div>
    );
};



export default AdminAlpSpreadsheet;
