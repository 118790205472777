import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaUserCircle, FaBars, FaSignOutAlt } from 'react-icons/fa';
import styled from 'styled-components';
import logo from '../img/globe1.png';
import '../App.css';
import './Navbar.css';

function CustomNavbar({ currentPage }) {
    const [menuOpen, setMenuOpen] = useState(false);
    const [darkMode, setDarkMode] = useState(() => {
        const savedTheme = localStorage.getItem('theme');
        return savedTheme ? savedTheme === 'dark' : false;
    });
    const [greeting, setGreeting] = useState('');

    useEffect(() => {
        document.body.classList.toggle('dark-mode', darkMode);
    }, [darkMode]);

    useEffect(() => {
        const agnName = localStorage.getItem('agnName');
        if (agnName) {
            const nameParts = agnName.split(' ');
            const firstName = nameParts[1]; // Assuming the format "last first middle suffix"
            const hour = new Date().getHours();

            let timeGreeting;
            if (hour < 12) {
                timeGreeting = 'Good morning';
            } else if (hour < 18) {
                timeGreeting = 'Good afternoon';
            } else {
                timeGreeting = 'Good evening';
            }

            setGreeting(`${timeGreeting}, ${firstName}`);
        }
    }, []);

    const navigate = useNavigate();
    const userRole = localStorage.getItem('userRole');
    const popupWindows = React.useRef([]); // Use ref to persist popup windows across renders

    const toggleDarkMode = () => {
        setDarkMode(prevMode => {
            const newMode = !prevMode;
            localStorage.setItem('theme', newMode ? 'dark' : 'light');
            document.body.classList.toggle('dark-mode', newMode);
            return newMode;
        });
    };

    const handleLogout = () => {
        localStorage.removeItem('userToken');
        localStorage.removeItem('userRole');
        localStorage.removeItem('userId');
        localStorage.removeItem('userInfo');
        navigate('/login');
    };

    const handleVerificationSurveyClick = () => {
        const userId = localStorage.getItem('userId');
        const userToken = localStorage.getItem('userToken');
        const url = `https://ariaslife.com/verify?b=${userToken}&a=${userId}`;
        const newTab = window.open(url, '_blank');

        const sendMessage = () => {
            newTab.postMessage({ userId, userToken }, 'https://ariaslife.com');
        };

        const maxAttempts = 5;
        let attempts = 0;

        const interval = setInterval(() => {
            if (attempts >= maxAttempts) {
                clearInterval(interval);
            } else {
                sendMessage();
                attempts += 1;
            }
        }, 1000);
    };

    const handleLaunchPreso = () => {
        const userId = localStorage.getItem('userId');
        const userToken = localStorage.getItem('userToken');
        const presFile = `https://ariaslife.com/login?userId=${userId}&userToken=${userToken}`;
        openPres(presFile);
    };
    
    const openPres = (presFile) => {
        const screenWidth = window.screen.availWidth;
        const screenHeight = window.screen.availHeight;
        const width = screenWidth > 3800 ? Math.round(screenWidth * 0.35) : Math.round(screenWidth * 0.7);
        const height = Math.round(screenHeight * 0.7);
        const left = screenWidth > 3800 ? Math.round((screenWidth - width) / 2.61) : 0;
        const top = screenHeight - height;
    
        let openedWindow = null;
        for (const win of popupWindows.current) {
            if (!win.closed) {
                openedWindow = win;
                break;
            }
        }
    
        if (openedWindow) {
            openedWindow.location.href = presFile;
            openedWindow.focus();
            openedWindow.moveToTop();
        } else {
            const presWindow = window.open(
                presFile,
                '_blank',
                `toolbar=no,scrollbars=no,resizable=yes,top=${top},left=${left},width=${width},height=${height},alwaysRaised=true`
            );
            popupWindows.current.push(presWindow);
            presWindow.focus();
        }
    };
    

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    if (!userRole) {
        return (
            <Navbar className="navbar-sticky" collapseOnSelect expand="md">
                <Container>
                    <Navbar.Brand href="/">
                        <img src={logo} width="45" height="45" alt="Logo" />
                    </Navbar.Brand>
                </Container>
            </Navbar>
        );
    }

    return (
        <>
            <Navbar className="navbar-sticky" collapseOnSelect expand="md">
                <Container style={{ maxWidth: '1400px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <HamburgerIcon onClick={toggleMenu}>
                            {<FaBars />}
                        </HamburgerIcon>
                        <Navbar.Brand href="/">
    <img src={logo} width="45" height="45" alt="Logo" />
</Navbar.Brand>
<span style={{ color: 'white' }}>{currentPage}</span>

                    </div>
                    <div className="navbar-links">
                    <a href="/reports" style={reportActivityLinkStyle} className="navbar-link">
    Report Activity
</a>

                        <button
 onClick={() => openPres('https://ariaslife.com/temp/agent_tools/presentation/pres_setup.html')} style={launchPresoStyle}
    className="navbar-button"
>
    Launch Preso
</button>

                    </div>
                </Container>
            </Navbar>
            <SideNav isOpen={menuOpen}>
                <NavItem href="/account" onClick={toggleMenu}>
                    <FaUserCircle size={24} />
                </NavItem>
                <NavItem href="/" onClick={toggleMenu}>Dashboard</NavItem>
                <NavItem href="/reports" onClick={toggleMenu}>Report Activity</NavItem>
                {(userRole === 'MGA' || userRole === 'RGA') && (
                    <>
                        <NavItem href="/more" onClick={toggleMenu}>M.O.R.E.</NavItem>
                    </>
                )}

                <NavItem href="/pipeline" onClick={toggleMenu}>Pipeline</NavItem>
                <NavItem href='/verify' onClick={toggleMenu}>Verification</NavItem>
                <NavItem href='/release' onClick={toggleMenu}>Release</NavItem>
                <DropdownContainer>
                    <DropdownHeader>Agent Sites</DropdownHeader>
                    <DropdownContent>
    <DropdownItem href="https://login.ailife.com/ImpactMobile/" target="_blank" rel="noopener noreferrer">
        Impact Mobile
    </DropdownItem>
    <DropdownItem href="https://login.ailife.com/ImpactPortal/" target="_blank" rel="noopener noreferrer">
        Impact AWS
    </DropdownItem>
    <DropdownItem href="https://payeeweb.ailicm.globelifeinc.com/payeewebv2/login?nextPathname=%2F" target="_blank" rel="noopener noreferrer">
        ICM
    </DropdownItem>
    <DropdownItem href="https://thekeefersuccess.com/WinnersCircle/agent_tools/presentation/option_builder_solo.html" target="_blank" rel="noopener noreferrer">
        Option Builder
    </DropdownItem>

</DropdownContent>

                </DropdownContainer>
                <NavItem onClick={handleLogout} style={{ marginTop: 'auto', paddingBottom: '40px' }}>
                    Logout
                </NavItem>
            </SideNav>
            <Overlay isOpen={menuOpen} onClick={toggleMenu} />
        </>
    );
}

const HamburgerIcon = styled.div`
    font-size: 20px;
    color: white;
    cursor: pointer;
    margin-right: 20px;
    z-index: 2000;
`;

const SideNav = styled.div`
    height: 100%;
    width: 160px;
    position: fixed;
    top: 0;
    left: ${({ isOpen }) => (isOpen ? '0' : '-250px')};
    background-color: #00558c;
    overflow-x: hidden;
    transition: 0.3s;
    z-index: 1500;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
`;

const NavItem = styled.a`
    padding: 8px 8px 8px 8px;
    text-decoration: none;
    font-size: 15px;
    color: white;
    display: block;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
        color: grey;
    }
`;

const DropdownItem = styled.a`
    cursor: pointer;
    color: white;
    transition: 0.3s;
    text-decoration: none;
    padding: 8px;
    display: block;
    width: 100%;
    text-align: left;

    &:hover {
        color: grey;
        background-color: #00548ceb;
    }
`;


const DropdownContainer = styled.div`
    width: 100%;
    color: white;
    text-align: center;
    cursor: pointer;
    margin-bottom: 8px;
    position: relative;
`;

const DropdownHeader = styled.div`
    padding: 8px;
    background-color: #00558c;
    color: white;
    border: none;
    cursor: pointer;
    text-align: center;
`;

const DropdownContent = styled.div`
    max-height: 0;
    overflow: hidden;
    position: relative;
    background-color: #00548ceb;
    z-index: 1;
    width: 100%;
    left: 0;
    transition: max-height 0.3s ease-in-out;

    ${DropdownContainer}:hover & {
        max-height: 500px; /* adjust as needed */
    }
`;

const Overlay = styled.div`
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.3);
`;

const reportActivityLinkStyle = {
    color: 'white',
    textDecoration: 'none',
    fontSize: '1rem',
    marginRight: '15px'
};

const launchPresoStyle = {
    background: '#28a745',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    padding: '5px 8px',
    fontSize: '1rem',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
};

export default CustomNavbar;
