import React, { useEffect, useState } from 'react';
import './GridContent.css';
import Content from './Content';
import AddResource from './AddResource';
import AdminManageResources from './AdminManageResources';

const Resources = () => {
  const userRole = localStorage.getItem('userRole');
  const agnName = localStorage.getItem('agnName');

  const [showModal, setShowModal] = useState(false); // Control modal display
  const [resourceToEdit, setResourceToEdit] = useState(null); // Resource to be edited

  const handleEdit = (resource) => {
    setResourceToEdit(resource); // Set resource to be edited
    setShowModal(true); // Open the modal
  };

  return (
    <div>


      {/* If userRole is Admin or agnName is VANBIBBER KYLE A or KEEFER JAMES E, show AdminManageResources */}
      {userRole === 'Admin' || agnName === 'VANBIBBER KYLE A' || agnName === 'KEEFER JAMES E' ? (
        <AdminManageResources />
      ) : (
        // If not Admin, VANBIBBER KYLE A, or KEEFER JAMES E, show Content
        <Content handleEdit={handleEdit} />
      )}
    </div>
  );
};

export default Resources;
